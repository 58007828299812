import React, { DispatchWithoutAction, FC, useContext } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import ModalContext from 'react-bootstrap/ModalContext';
import { UserEditDto } from './dto/UserEditDto';
import { api } from '../functions/api';
import { ApiPath } from '../enums/ApiPath';
import { useSaveItem } from '../hooks/useSaveItem';
import { User } from './dto/User';
import { useUserEditForm } from './hooks/useUserEditForm';
import { useTranslation } from 'react-i18next';
import { FormModalFooter } from '../components/form/FormModalFooter';
import { ModalHeader } from '../components/form/ModalHeader';
import { UsersEmailField } from './UsersEmailField';

interface Props {
  update: DispatchWithoutAction;
  user: User;
}

export const UsersEdit: FC<Props> = ({ update, user }) => {
  const { t } = useTranslation();
  const form = useUserEditForm(user);
  const { onHide } = useContext(ModalContext);
  const save = useSaveItem<UserEditDto>((data) =>
    api({
      url: ApiPath.User.replace(':id', user.id.toString()),
      method: 'patch',
      data,
    }),
  );
  const onSubmit = (data: UserEditDto) => {
    save(data).then(() => {
      update();
      onHide();
    });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalHeader>{t('Edit user')}</ModalHeader>

        <Modal.Body>
          <UsersEmailField />
        </Modal.Body>

        <FormModalFooter />
      </Form>
    </FormProvider>
  );
};
