import { DashboardBaseAsset } from '../types/DashboardBaseAsset';
import { DashboardTableBaseBatch } from './types/DashboardTableBaseBatch';
import { action, computed, makeObservable, observable } from 'mobx';
import { DashboardTableBaseAsset } from './types/DashboardTableBaseAsset';
import { DispatchWithoutAction } from 'react';

export class DashboardTableBatchesFactory<
  T extends DashboardBaseAsset,
  A extends DashboardTableBaseAsset,
  B extends DashboardTableBaseBatch<A, T>,
> {
  batchesByDate = observable.map<string, B>();

  constructor(private createBatch: (date: string, assets: T[], remove: DispatchWithoutAction) => B) {
    makeObservable(this, {
      updateAssets: action,
      batches: computed,
      assetsCount: computed,
    });
  }

  get batches(): B[] {
    return Array.from(this.batchesByDate.values());
  }

  get assetsCount(): number {
    let count = 0;

    this.batchesByDate.forEach((batch) => {
      count += batch.assetsById.size;
    });

    return count;
  }

  updateAssets(assets: T[]) {
    const assetsByDate = this.groupAssetsByDate(assets);

    this.removeMissingBatches(assetsByDate);

    assetsByDate.forEach((assets, date) => this.processDateAssets(date, assets));
  }

  /**
   * The new set of assets may not have a previously existing batches (dates). There may be situations where
   * the same assets have been seen at the gate multiple times, so their date will be changed.
   */
  private removeMissingBatches(assetsByDate: Map<string, T[]>) {
    this.batchesByDate.forEach((_, key) => {
      if (!assetsByDate.has(key)) {
        this.batchesByDate.delete(key);
      }
    });
  }

  private groupAssetsByDate(assets: T[]): Map<string, T[]> {
    const map = new Map<string, T[]>();

    assets.forEach((asset) => {
      const key = asset.date;
      const collection = map.get(key);

      if (collection) {
        collection.push(asset);
      } else {
        map.set(key, [asset]);
      }
    });

    return map;
  }

  private processDateAssets(date: string, assets: T[]): void {
    const batch = this.batchesByDate.get(date);

    if (batch) {
      batch.updateAssets(assets);
    } else {
      this.createNewBatch(date, assets);
    }
  }

  private createNewBatch(date: string, assets: T[]): void {
    this.batchesByDate.set(
      date,
      this.createBatch(date, assets, () => this.batchesByDate.delete(date)),
    );
  }
}
