import React, { FC, useState } from 'react';
import { DashboardButton } from './DashboardButton';
import './DashboardButtons.scss';
import { ArrowDownFromLine, ArrowUpFromLine } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { DashboardIssueDialog } from '../dialogs/DashboardIssueDialog';
import { DashboardReturnDialog } from '../dialogs/DashboardReturnDialog';

export const DashboardButtons: FC = () => {
  const { t } = useTranslation();
  const [issueDialogDisplayed, setIssueDialogDisplayed] = useState(false);
  const [returnDialogDisplayed, setReturnDialogDisplayed] = useState(false);

  return (
    <div className="DashboardButtons">
      <DashboardButton
        caption={t('Assign an asset to a customer')}
        icon={<ArrowUpFromLine />}
        onClick={() => {
          setIssueDialogDisplayed(true);
        }}
      >
        {t('Issue assets')}
      </DashboardButton>

      <DashboardButton
        caption={t('Unassign an asset from a customer')}
        icon={<ArrowDownFromLine />}
        onClick={() => {
          setReturnDialogDisplayed(true);
        }}
      >
        {t('Return assets')}
      </DashboardButton>

      <Modal show={issueDialogDisplayed} onHide={() => setIssueDialogDisplayed(false)}>
        <DashboardIssueDialog />
      </Modal>

      <Modal show={returnDialogDisplayed} onHide={() => setReturnDialogDisplayed(false)}>
        <DashboardReturnDialog />
      </Modal>
    </div>
  );
};
