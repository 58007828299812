import React, { useMemo } from 'react';
import { useTableSelectContext } from './TableSelectContext';
import { TableSelectCheckbox } from './TableSelectCheckbox';
import { Tooltip } from '../Tooltip';

interface Props<T> {
  item: T;
  disablingReason?: string;
}

export function TableSelectItem<T>({ item, disablingReason }: Props<T>) {
  const { setSelectedItems, allItems, selectedItems, selectConditionFn } = useTableSelectContext();
  const checked = useMemo<boolean>(
    () => allItems.length === selectedItems.size || selectedItems.has(item),
    [allItems, selectedItems, item],
  );
  const enabled = selectConditionFn ? selectConditionFn(item, selectedItems) : true;
  const disabled = !enabled;

  const checkbox = (
    <TableSelectCheckbox
      disabled={disabled}
      onChange={(event) => {
        const { checked } = event.target;

        setSelectedItems((selectedItems) => {
          if (checked) {
            selectedItems.add(item);
          } else {
            selectedItems.delete(item);
          }

          return new Set(selectedItems);
        });
      }}
      checked={checked}
    />
  );

  return disabled && disablingReason ? <Tooltip tooltip={disablingReason}>{checkbox}</Tooltip> : checkbox;
}
