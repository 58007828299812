import { useMemo, useState } from 'react';
import { createStringFilterFunction } from '../../components/table/functions/createStringFilterFunction';
import { AdminTableInputFilter } from '../../components/table/filters/AdminTableInputFilter';
import { AdminTableColumn } from '../../components/table/interfaces/AdminTableColumn';
import { Asset } from '../dto/Asset';
import { useTranslation } from 'react-i18next';
import { assetsInitialFilters } from '../assetsInitialFilters';

export function useAssetsSubcategoryColumn(): AdminTableColumn<Asset> {
  const { t } = useTranslation();
  const initialValue = useState(() => assetsInitialFilters.extractSubcategory())[0];

  return useMemo(
    () => ({
      title: t('Subcategory'),
      predicate: (item) => item.subcategory,
      sortingPredicate: (item) => item.subcategory,
      filterConfig: {
        component: AdminTableInputFilter,
        initialValue,
        filterFn: createStringFilterFunction((item) => item.subcategory),
      },
    }),
    [t, initialValue],
  );
}
