import { DispatchWithoutAction } from 'react';
import { AssetsMultipleAssignFromData } from './dto/AssetsMultipleAssignFromData';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { assignAssets } from './functions/assignAssets';
import { STOCK_CUSTOMER_ID } from '../constants';

export function useAssetsMultipleAssignFromSubmitHandler(
  onSuccess: DispatchWithoutAction,
): SubmitHandler<AssetsMultipleAssignFromData> {
  const { t } = useTranslation();

  return (data: AssetsMultipleAssignFromData) => {
    const id = toast.loading(t('Saving'));
    const customerId = data.customer.value === STOCK_CUSTOMER_ID ? null : data.customer.value;

    return assignAssets({
      assetIds: data.assetIds,
      reassign: data.reassign,
      customerId,
    })
      .then(() => {
        toast.success(t('Saved successfully'), { id });

        onSuccess();
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status === 409) {
          toast.error(t('Some assets are already assigned'), { id });
        } else {
          toast.error(t('Error while saving'), { id });
        }

        return Promise.reject(reason);
      });
  };
}
