import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { DashboardTableReturnedAsset } from './DashboardTableReturnedAsset';
import { DashboardTableReturnedBatch } from './DashboardTableReturnedBatch';
import { useTranslation } from 'react-i18next';
import { DashboardTableActionsButton } from '../actions/DashboardTableActionsButton';
import { DashboardTableTimerCancelButton } from '../actions/DashboardTableTimerCancelButton';
import { DashboardTableLabel } from '../actions/DashboardTableLabel';
import { DashboardTableActionsDeleteButton } from '../actions/DashboardTableActionsDeleteButton';
import { DashboardTableActions } from '../DashboardTableActions';

export const DashboardTableReturnedAssetActions: FC<{
  batch: DashboardTableReturnedBatch;
  asset: DashboardTableReturnedAsset;
}> = observer(({ asset }) => {
  const { t } = useTranslation();

  return (
    <DashboardTableActions>
      {asset.requestInProgress ? (
        <DashboardTableLabel>{t('Loading')}</DashboardTableLabel>
      ) : asset.batchCancelCountdown ? null : asset.cancelCountdown ? (
        <DashboardTableTimerCancelButton
          countdown={asset.cancelCountdown}
          onClick={() => {
            asset.stopCancelCountdown();
          }}
        />
      ) : (
        <DashboardTableActionsButton
          onClick={async () => {
            await asset.approve();
          }}
        >
          {t('Approve')}
        </DashboardTableActionsButton>
      )}

      <DashboardTableActionsDeleteButton
        onDelete={async () => {
          await asset.delete();
        }}
      >
        {t('Cancel')}
      </DashboardTableActionsDeleteButton>
    </DashboardTableActions>
  );
});
