import { ArcElement, BarElement, CategoryScale, Chart, Colors, Legend, LinearScale, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Colors, ChartDataLabels);

Chart.defaults.maintainAspectRatio = false;
Chart.defaults.plugins.legend.position = 'bottom';
Chart.defaults.plugins.legend.labels.pointStyle = 'circle';
Chart.defaults.plugins.legend.labels.usePointStyle = true;
Chart.defaults.plugins.legend.rtl = process.env.REACT_APP_LANG === 'he';
Chart.defaults.plugins.legend.labels.boxWidth = 6;
Chart.defaults.plugins.legend.labels.boxHeight = 6;
Chart.defaults.plugins.datalabels = {
  display: false,
  align: process.env.REACT_APP_LANG === 'he' ? 'right' : undefined,
};
