import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { DashboardTableReturnedBatch } from './DashboardTableReturnedBatch';
import { useTranslation } from 'react-i18next';
import { DashboardTableActionsButton } from '../actions/DashboardTableActionsButton';
import { DashboardTableTimerCancelButton } from '../actions/DashboardTableTimerCancelButton';
import { DashboardTableLabel } from '../actions/DashboardTableLabel';
import { DashboardTableActionsDeleteButton } from '../actions/DashboardTableActionsDeleteButton';
import { DashboardTableActions } from '../DashboardTableActions';

export const DashboardTableReturnedBatchActions: FC<{
  batch: DashboardTableReturnedBatch;
}> = observer(({ batch }) => {
  const { t } = useTranslation();

  return (
    <DashboardTableActions>
      {batch.batchRequestInProgress ? (
        <DashboardTableLabel>{t('Loading')}</DashboardTableLabel>
      ) : batch.cancelCountdown ? (
        <DashboardTableTimerCancelButton
          countdown={batch.cancelCountdown}
          onClick={() => {
            batch.stopCancelCountdown();
          }}
        />
      ) : batch.canBeProcessedAssets.length ? (
        <DashboardTableActionsButton
          onClick={async () => {
            await batch.approveBatch();
          }}
        >
          {t('Approve batch')}
        </DashboardTableActionsButton>
      ) : null}

      <DashboardTableActionsDeleteButton
        onDelete={async () => {
          await batch.delete();
        }}
      >
        {t('Cancel')}
      </DashboardTableActionsDeleteButton>
    </DashboardTableActions>
  );
});
