import { api } from '../../functions/api';
import { ApiPath } from '../../enums/ApiPath';

type Data = {
  customerId: number | null;
  assetIds: number[];
  reassign: boolean;
};

export async function assignAssets(data: Data): Promise<void> {
  return api({
    url: ApiPath.AssetsAssign,
    method: 'post',
    data,
  });
}
