import { FC } from 'react';
import './DashboardReportValue.scss';
import classNames from 'classnames';

export const DashboardReportWarningValue: FC<{
  critical?: boolean;
  value: number;
}> = ({ value, critical = false }) => {
  return (
    <div className={classNames('DashboardReportValue', value !== 0 ? (critical ? '-red' : '-yellow') : null)}>
      {value}
    </div>
  );
};
