import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { DashboardTableUnassignedBatch } from './DashboardTableUnassignedBatch';
import { DashboardTableUnassignedAsset } from './DashboardTableUnassignedAsset';
import { useTranslation } from 'react-i18next';
import { DashboardTableTimerCancelButton } from '../actions/DashboardTableTimerCancelButton';
import { DashboardTableUnassignedSelect } from './DashboardTableUnassignedSelect';
import { DashboardTableLabel } from '../actions/DashboardTableLabel';
import { DashboardTableActionsDeleteButton } from '../actions/DashboardTableActionsDeleteButton';
import { DashboardTableActions } from '../DashboardTableActions';

export const DashboardTableUnassignedAssetActions: FC<{
  batch: DashboardTableUnassignedBatch;
  asset: DashboardTableUnassignedAsset;
}> = observer(({ asset }) => {
  const { t } = useTranslation();

  return (
    <DashboardTableActions>
      {asset.requestInProgress ? (
        <DashboardTableLabel>{t('Loading')}</DashboardTableLabel>
      ) : asset.batchCancelCountdown ? null : asset.cancelCountdown ? (
        <DashboardTableTimerCancelButton
          countdown={asset.cancelCountdown}
          onClick={() => {
            asset.stopCancelCountdown();
          }}
        />
      ) : (
        <DashboardTableUnassignedSelect
          placeholder={t('Assign')}
          onCustomerSelect={async (customer) => {
            await asset.assign(customer);
          }}
          style={{ width: 80 }}
        />
      )}

      <DashboardTableActionsDeleteButton
        onDelete={async () => {
          await asset.delete();
        }}
      >
        {t('Cancel')}
      </DashboardTableActionsDeleteButton>
    </DashboardTableActions>
  );
});
