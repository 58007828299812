import { DashboardTableBaseBatch } from '../types/DashboardTableBaseBatch';
import { DashboardTableReturnedAsset } from './DashboardTableReturnedAsset';
import { DashboardReturnedAsset } from '../../types/DashboardReturnedAsset';
import { action, makeObservable } from 'mobx';
import { DispatchWithoutAction } from 'react';
import { assignAssets } from '../assignAssets';

export class DashboardTableReturnedBatch extends DashboardTableBaseBatch<
  DashboardTableReturnedAsset,
  DashboardReturnedAsset
> {
  constructor(dateString: string, assets: DashboardReturnedAsset[], remove: DispatchWithoutAction) {
    super(dateString, assets, remove);

    makeObservable(this, {
      approveBatch: action,
    });
  }

  async approveBatch() {
    await this.startBatchProcessing(async (assets) => {
      await assignAssets(assets.map((asset) => asset.id));
    }).finally(() => {
      this.batchCancelCountdownAssets.forEach((asset) => {
        asset.setBatchCancelCountdown(null);
      });
    });
  }

  createAsset(asset: DashboardReturnedAsset): DashboardTableReturnedAsset {
    return new DashboardTableReturnedAsset(asset, () => this.removeAsset(asset.id));
  }
}
