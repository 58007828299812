import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { DashboardContext } from '../../DashboardContext';
import { DashboardTableHeader } from '../DashboardTableHeader';
import { DashboardTableBatches } from '../DashboardTableBatches';
import { DashboardTableReturnedAssetActions } from './DashboardTableReturnedAssetActions';
import { DashboardTableReturnedBatchActions } from './DashboardTableReturnedBatchActions';
import { DashboardTableReturnedBatch } from './DashboardTableReturnedBatch';
import { DashboardTableReturnedAsset } from './DashboardTableReturnedAsset';

export const DashboardTableReturned: FC = observer(() => {
  const { t } = useTranslation();
  const { batches } = useContext(DashboardContext).returnedBatchesFactory;

  return (
    <>
      <DashboardTableHeader
        title={t('Returned assets')}
        description={t('Assets returned to warehouse but not detached from customer.')}
      />

      <DashboardTableBatches<DashboardTableReturnedAsset, DashboardTableReturnedBatch>
        batches={batches}
        BatchActions={DashboardTableReturnedBatchActions}
        AssetActions={DashboardTableReturnedAssetActions}
      />
    </>
  );
});
