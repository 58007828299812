import React, { useMemo } from 'react';
import { AssetImageColumn } from '../AssetImageColumn';
import { createStringFilterFunction } from '../../components/table/functions/createStringFilterFunction';
import { AdminTableInputFilter } from '../../components/table/filters/AdminTableInputFilter';
import { createBooleanFilterFunction } from '../../components/table/functions/createBooleanFilterFunction';
import { AdminTableBooleanFilter } from '../../components/table/filters/AdminTableBooleanFilter';
import { AdminDate } from '../../components/table/AdminDate';
import { AdminTableColumn } from '../../components/table/interfaces/AdminTableColumn';
import { Asset } from '../dto/Asset';
import { createDateFilterFunction } from '../../components/table/functions/createDateFilterFunction';
import { AdminTableDateFilter } from '../../components/table/filters/AdminTableDateFilter';
import { useTranslation } from 'react-i18next';
import { useAssetMaintenanceColumn } from './useAssetMaintenanceColumn';
import { useAssetSelectColumn } from './useAssetSelectColumn';
import { useAssetsTakenColumn } from '../taken/useAssetsTakenColumn';
import { useAssetsSubcategoryColumn } from './useAssetsSubcategoryColumn';

export function useAssetColumns(): AdminTableColumn<Asset>[] {
  const { t } = useTranslation();
  const selectColumn = useAssetSelectColumn();
  const subcategoryColumn = useAssetsSubcategoryColumn();
  const maintenanceColumn = useAssetMaintenanceColumn();
  const takenColumn = useAssetsTakenColumn();

  return useMemo(
    () => [
      selectColumn,
      {
        title: t('Title'),
        predicate: (item) => item.title,
        sortingPredicate: (item) => item.title,
        filterConfig: {
          filterFn: createStringFilterFunction((item) => item.title),
          component: AdminTableInputFilter,
        },
      },
      {
        title: t('GUID'),
        predicate: (item) => item.guid,
        sortingPredicate: (item) => item.guid,
        filterConfig: {
          filterFn: createStringFilterFunction((item) => item.guid),
          component: AdminTableInputFilter,
        },
      },
      {
        title: t('Category'),
        predicate: (item) => item.category,
        sortingPredicate: (item) => item.category,
        filterConfig: {
          filterFn: createStringFilterFunction((item) => item.category),
          component: AdminTableInputFilter,
        },
      },
      subcategoryColumn,
      {
        title: t('Image'),
        component: AssetImageColumn,
        sortingPredicate: (item) => !!item.imageUrl,
        filterConfig: {
          filterFn: createBooleanFilterFunction((item) => !!item.imageUrl),
          component: AdminTableBooleanFilter,
        },
        style: {
          width: 76,
        },
      },
      maintenanceColumn,
      takenColumn,
      {
        title: t('Created at'),
        predicate: (item) => <AdminDate date={item.createdAt} />,
        sortingPredicate: (item) => item.createdAt,
        filterConfig: {
          filterFn: createDateFilterFunction((item) => item.createdAt),
          component: AdminTableDateFilter,
        },
        style: {
          width: 160,
        },
      },
    ],
    [t, selectColumn, subcategoryColumn, maintenanceColumn, takenColumn],
  );
}
