import { DashboardDialogSelectOption } from './types/DashboardDialogSelectOption';
import { DashboardDialogAssetsRequestPayload } from './DashboardDialogAssetsRequestPayload';
import { DashboardDialogAssetOption } from './types/DashboardDialogAssetOption';
import { DashboardDialogSubcategoryOption } from './types/DashboardDialogSubcategoryOption';

export class DashboardDialogAssetsRequestPayloadFactory {
  private assetIds: number[] = [];
  private subcategories = new Map<string, number>();

  constructor(options: DashboardDialogSelectOption[]) {
    options.map((option) => this.processOption(option));
  }

  getRequestPayload(): DashboardDialogAssetsRequestPayload {
    return {
      assetIds: this.assetIds,
      subcategories: this.getPayloadSubcategories(),
    };
  }

  protected getPayloadSubcategories(): DashboardDialogAssetsRequestPayload['subcategories'] {
    return Array.from(this.subcategories.entries()).map(([name, count]) => ({
      name,
      count,
    }));
  }

  private processOption(option: DashboardDialogSelectOption) {
    if (option instanceof DashboardDialogAssetOption) {
      this.processAssetOption(option);
    } else if (option instanceof DashboardDialogSubcategoryOption) {
      this.processSubcategoryOption(option);
    }
  }

  private processAssetOption(option: DashboardDialogAssetOption) {
    this.assetIds.push(option.asset.id);
  }

  private processSubcategoryOption({ subcategory }: DashboardDialogSubcategoryOption) {
    this.subcategories.set(subcategory, (this.subcategories.get(subcategory) || 0) + 1);
  }
}
