import { FormSelectOption } from '../../components/form/FormSelect';
import { Customer } from '../../customers/dto/Customer';
import { useContext, useMemo } from 'react';
import { DashboardContext } from '../DashboardContext';

export function useDashboardCustomerSelectOptions(): FormSelectOption<Customer>[] {
  const { data: customers = [] } = useContext(DashboardContext).customers;

  return useMemo<FormSelectOption<Customer>[]>(
    () =>
      customers.map((value) => ({
        label: value.name,
        value,
      })),
    [customers],
  );
}
