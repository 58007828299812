import React, { ComponentType, PropsWithChildren, ReactNode, useState } from 'react';
import { DashboardTableAsset } from './DashboardTableAsset';
import { observer } from 'mobx-react-lite';
import { DashboardTableBaseBatch } from './types/DashboardTableBaseBatch';
import { DashboardTableBaseAsset } from './types/DashboardTableBaseAsset';
import { DashboardTableBatchHeader } from './DashboardTableBatchHeader';
import './DashboardTableBatch.scss';

export const DashboardTableBatch = observer(function <
  A extends DashboardTableBaseAsset,
  B extends DashboardTableBaseBatch<A>,
>({
  batch,
  actions,
  AssetActions,
}: PropsWithChildren<{
  batch: B;
  actions: ReactNode;
  AssetActions: ComponentType<{ batch: B; asset: A }>;
}>) {
  const [opened, setOpened] = useState(false);

  return (
    <div className="DashboardTableBatch">
      <div className="header" onClick={() => setOpened(!opened)}>
        <DashboardTableBatchHeader<A, B> batch={batch} opened={opened} actions={actions} />
      </div>

      {opened && (
        <div className="assets">
          {batch.assets.map((asset) => (
            <DashboardTableAsset asset={asset} key={asset.id} actions={<AssetActions batch={batch} asset={asset} />} />
          ))}
        </div>
      )}
    </div>
  );
});
