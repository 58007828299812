import React, { FC, useMemo, useState } from 'react';
import { AdminTable } from '../components/table/AdminTable';
import { ApiPath } from '../enums/ApiPath';
import { AdminTableActionsItem } from '../components/table/interfaces/AdminTableActionsItem';
import { AdminToolbar } from '../components/toolbar/AdminToolbar';
import { AdminToolbarTitle } from '../components/toolbar/AdminToolbarTitle';
import { Customer } from './dto/Customer';
import { AdminToolbarActions } from '../components/toolbar/AdminToolbarActions';
import { AdminToolbarButton } from '../components/toolbar/AdminToolbarButton';
import { CustomersCreate } from './CustomersCreate';
import { Modal } from 'react-bootstrap';
import { CustomersEdit } from './CustomersEdit';
import { api } from '../functions/api';
import { useDeleteItem } from '../hooks/useDeleteItem';
import { useCustomerColumns } from './hooks/useCustomerColumns';
import { useCustomers } from '../hooks/useCustomers';
import { useTranslation } from 'react-i18next';

export const Customers: FC = () => {
  const { t } = useTranslation();
  const [editableCustomer, setEditableCustomer] = useState<Customer | null>(null);
  const deleteCustomer = useDeleteItem<Customer>((item) =>
    api({
      url: ApiPath.Customer.replace(':id', item.id.toString()),
      method: 'delete',
    }),
  );
  const { data = [], isLoading, reload } = useCustomers();
  const actions = useMemo<AdminTableActionsItem<Customer>[]>(
    () => [
      {
        title: t('Edit').toString(),
        onClick: setEditableCustomer,
      },
      {
        title: t('Delete').toString(),
        onClick: (item) => {
          if (window.confirm(`Are you sure you want to delete?`)) {
            deleteCustomer(item).then(() => reload());
          }
        },
      },
    ],
    [deleteCustomer, reload, t],
  );
  const columns = useCustomerColumns();
  const [adding, setAdding] = useState(false);

  return (
    <>
      <AdminToolbar>
        <AdminToolbarTitle>{t('Customers')}</AdminToolbarTitle>

        <AdminToolbarActions>
          <AdminToolbarButton onClick={() => setAdding(true)}>{t('Add customer')}</AdminToolbarButton>
        </AdminToolbarActions>
      </AdminToolbar>

      <AdminTable items={data} loading={isLoading} columns={columns} actions={actions} />

      <Modal show={adding} onHide={() => setAdding(false)}>
        <CustomersCreate update={reload} />
      </Modal>

      <Modal show={!!editableCustomer} onHide={() => setEditableCustomer(null)}>
        {editableCustomer ? <CustomersEdit update={reload} customer={editableCustomer} /> : null}
      </Modal>
    </>
  );
};
