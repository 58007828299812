import { FC } from 'react';
import Select, { ActionMeta, components, MultiValue } from 'react-select';
import {
  AssetsTakenColumnFilterGroupedOptions,
  useAssetsTakenColumnFilterOptions,
} from './useAssetsTakenColumnFilterOptions';
import { AssetsTakenColumnFilterOption } from './AssetsTakenColumnFilterOption';
import { AssetTakenColumnFilterSingleValue } from './AssetTakenColumnFilterSingleValue';
import { AssetsTakenColumnFilterChangeHandler } from './useAssetsTakenColumnFilterChangeHandler';

export const AssetTakenColumnFilterSelect: FC<{
  groupedOptions: AssetsTakenColumnFilterGroupedOptions;
  selectedOptions: MultiValue<AssetsTakenColumnFilterOption>;
  changeHandler: AssetsTakenColumnFilterChangeHandler;
}> = ({ groupedOptions, selectedOptions, changeHandler }) => {
  const options = useAssetsTakenColumnFilterOptions(groupedOptions);

  return (
    <Select<AssetsTakenColumnFilterOption, true>
      placeholder={null}
      value={selectedOptions}
      options={options}
      components={{
        IndicatorSeparator: null,
        ClearIndicator: () => null,
        DropdownIndicator: () => null,
        MultiValue: (props) => {
          const option = props.data as AssetsTakenColumnFilterOption;

          if (option.value === null) {
            return null;
          } else if (typeof option.value === 'boolean') {
            return <AssetTakenColumnFilterSingleValue option={option} />;
          } else {
            const Component = components.MultiValue;

            return <Component {...props}>{props.children}</Component>;
          }
        },
      }}
      styles={{
        menu: (base) => ({
          ...base,
          left: 'auto',
          right: 0,
          width: 'auto',
        }),
        container: (base) => ({
          ...base,
          minHeight: 28,
        }),
        control: (base) => ({
          ...base,
          minHeight: 28,
          boxShadow: 'none',
          border: '1px solid #ced4da',
          '&:hover': {
            border: '1px solid #8992d8',
          },
          '&:focus': {
            border: '1px solid #8992d8',
          },
        }),
        option: (base) => ({
          ...base,
          minWidth: 200,
        }),
        input: (base) => ({
          ...base,
          height: 22,
          maxWidth: 100,
          margin: '0 0 0 4px',
          padding: 0,
        }),
        valueContainer: (base) => ({
          ...base,
          padding: 2,
        }),
        multiValue: (base) => ({
          ...base,
          gap: 2,
          alignItems: 'center',
          maxWidth: 'calc(100% - 12px)',
          lineHeight: '14px',
          paddingLeft: 4,
          margin: 2,
          background: '#1225b1',
          color: 'white',
        }),
        multiValueLabel: (base) => ({
          ...base,
          padding: 0,
          paddingLeft: 0,
          margin: 0,
          color: 'white',
          fontWeight: 500,
        }),
        multiValueRemove: (base) => ({
          ...base,
          padding: 2,
          '&:hover': {
            background: '#f91212',
            color: 'white',
          },
          '&:active': {
            background: '#e11313',
            color: 'white',
          },
        }),
      }}
      onChange={(value: MultiValue<AssetsTakenColumnFilterOption>, meta: ActionMeta<AssetsTakenColumnFilterOption>) => {
        changeHandler(value, meta);
      }}
      isMulti
    />
  );
};
