import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { DashboardTableUnassignedBatch } from './DashboardTableUnassignedBatch';
import { useTranslation } from 'react-i18next';
import { DashboardTableTimerCancelButton } from '../actions/DashboardTableTimerCancelButton';
import { DashboardTableUnassignedSelect } from './DashboardTableUnassignedSelect';
import { DashboardTableLabel } from '../actions/DashboardTableLabel';
import { DashboardTableActionsDeleteButton } from '../actions/DashboardTableActionsDeleteButton';
import { DashboardTableActions } from '../DashboardTableActions';

export const DashboardTableUnassignedBatchActions: FC<{
  batch: DashboardTableUnassignedBatch;
}> = observer(({ batch }) => {
  const { t } = useTranslation();

  return (
    <DashboardTableActions>
      {batch.batchRequestInProgress ? (
        <DashboardTableLabel>{t('Loading')}</DashboardTableLabel>
      ) : batch.cancelCountdown ? (
        <DashboardTableTimerCancelButton
          countdown={batch.cancelCountdown}
          onClick={() => {
            batch.cancelBatchAssigning();
          }}
        />
      ) : batch.canBeProcessedAssets.length ? (
        <DashboardTableUnassignedSelect
          placeholder={t('Assign batch')}
          onCustomerSelect={async (customer) => {
            await batch.assignBatch(customer);
          }}
        />
      ) : null}

      <DashboardTableActionsDeleteButton
        onDelete={async () => {
          await batch.delete();
        }}
      >
        {t('Cancel')}
      </DashboardTableActionsDeleteButton>
    </DashboardTableActions>
  );
});
