import React, { FC, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { FormField } from '../components/form/FormField';
import { AssetsCategoryControl } from './AssetsCategoryControl';
import { useTranslation } from 'react-i18next';
import { AssetsAlerts } from './AssetsAlerts';
import { NotesFormField } from '../components/NotesFormField';
import { ApiPath } from '../enums/ApiPath';
import { AssetsMaintenance } from './AssetsMaintenance';

interface Props {
  image?: ReactNode;
}

export const AssetsFields: FC<Props> = ({ image }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormField
        label={t('Title').toString()}
        name="title"
        render={({ invalid, controlAttrs }) => <Form.Control {...controlAttrs} isInvalid={invalid} autoFocus />}
      />

      <FormField
        label={t('GUID').toString()}
        name="guid"
        render={({ invalid, controlAttrs }) => <Form.Control {...controlAttrs} isInvalid={invalid} />}
      />

      <FormField label={t('Category').toString()} name="category">
        <AssetsCategoryControl apiPath={ApiPath.AssetCategories} />
      </FormField>

      <FormField label={t('Subcategory').toString()} name="subcategory">
        <AssetsCategoryControl apiPath={ApiPath.AssetSubcategories} />
      </FormField>

      <FormField
        label={t('Image').toString()}
        name="image"
        render={({ invalid, controlAttrs }) => <Form.Control type="file" {...controlAttrs} isInvalid={invalid} />}
      />

      {image}

      <AssetsMaintenance />

      <NotesFormField />
      <AssetsAlerts />
    </>
  );
};
