import { useMemo } from 'react';
import { useCustomers } from '../hooks/useCustomers';
import { useTranslation } from 'react-i18next';
import { GroupBase } from 'react-select';
import { FormSelectOption } from '../components/form/FormSelect';
import { STOCK_CUSTOMER_ID } from '../constants';

export type AssetsMultipleAssignOption = FormSelectOption<number>;
export type AssetsMultipleAssignOptions = Array<AssetsMultipleAssignOption | GroupBase<AssetsMultipleAssignOption>>;

export function useAssetsMultipleAssignOptions(reassign: boolean): AssetsMultipleAssignOptions {
  const { t } = useTranslation();
  const { data } = useCustomers();

  return useMemo<AssetsMultipleAssignOptions>(() => {
    const customerOptions: AssetsMultipleAssignOption[] = data
      ? data.map((customer) => ({
          label: customer.name,
          value: customer.id,
        }))
      : [];

    if (reassign) {
      return [
        {
          label: t('Return to stock').toString(),
          value: STOCK_CUSTOMER_ID,
        },
        {
          label: t('Customers').toString(),
          options: customerOptions,
        },
      ];
    } else {
      return customerOptions;
    }
  }, [t, data, reassign]);
}
