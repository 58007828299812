import { X } from 'lucide-react';
import { DashboardTableActionsButton } from './DashboardTableActionsButton';
import { ButtonHTMLAttributes, FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

export const DashboardTableActionsDeleteButton: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    onDelete: MouseEventHandler<HTMLButtonElement>;
  }
> = ({ onDelete, ...props }) => {
  const { t } = useTranslation();

  return (
    <DashboardTableActionsButton
      {...props}
      onClick={(event) => {
        if (window.confirm(t('Are you sure you want to delete?').toString())) {
          onDelete(event);
        }
      }}
    >
      <X size={14} />
    </DashboardTableActionsButton>
  );
};
