import React, { FC, useMemo } from 'react';
import format from 'date-fns/format';

interface Props {
  date: Date;
  format: string;
  className?: string;
}

export const DateFormatted: FC<Props> = ({ date, format: f, className }) => {
  const content = useMemo(() => format(date, f), [date, f]);

  return <span className={className}>{content}</span>;
};
