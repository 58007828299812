import { ButtonHTMLAttributes, FC } from 'react';
import classNames from 'classnames';
import './DashboardTableActionsButton.scss';

export const DashboardTableActionsButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  children,
  ...attrs
}) => {
  return (
    <button className={classNames('DashboardTableActionsButton', className)} {...attrs}>
      {children}
    </button>
  );
};
