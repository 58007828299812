import { DashboardTableBaseBatch } from '../types/DashboardTableBaseBatch';
import { DashboardTableUnassignedAsset } from './DashboardTableUnassignedAsset';
import { DashboardUnassignedAsset } from '../../types/DashboardUnassignedAsset';
import { action, makeObservable } from 'mobx';
import { DispatchWithoutAction } from 'react';
import { assignAssets } from '../assignAssets';
import { Customer } from '../../../customers/dto/Customer';

export class DashboardTableUnassignedBatch extends DashboardTableBaseBatch<
  DashboardTableUnassignedAsset,
  DashboardUnassignedAsset
> {
  constructor(dateString: string, assets: DashboardUnassignedAsset[], remove: DispatchWithoutAction) {
    super(dateString, assets, remove);

    makeObservable(this, {
      assignBatch: action,
    });
  }

  cancelBatchAssigning() {
    this.stopCancelCountdown();
  }

  async assignBatch(customer: Customer) {
    this.canBeProcessedAssets.forEach((asset) => asset.setTakenBy(customer.name));

    await this.startBatchProcessing((assets) =>
      assignAssets(
        assets.map((asset) => asset.id),
        customer.id,
      ),
    )
      .catch(() => {
        this.batchCancelCountdownAssets.forEach((asset) => {
          asset.setTakenBy(null);
        });
      })
      .finally(() => {
        this.batchCancelCountdownAssets.forEach((asset) => {
          asset.setBatchCancelCountdown(null);
        });
      });
  }

  createAsset(asset: DashboardUnassignedAsset): DashboardTableUnassignedAsset {
    return new DashboardTableUnassignedAsset(asset, () => this.removeAsset(asset.id));
  }
}
