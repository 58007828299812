import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardTableChevron } from './DashboardTableChevron';
import { DateFormatted } from '../../components/DateFormatted';
import { DATE_FORMAT } from '../../constants';
import { observer } from 'mobx-react-lite';
import { DashboardTableBaseBatch } from './types/DashboardTableBaseBatch';
import { DashboardTableBaseAsset } from './types/DashboardTableBaseAsset';
import { DashboardTableFirstColumn } from './DashboardTableFirstColumn';
import { DashboardTableSummary } from './DashboardTableSummary';
import { DashboardTableCustomer } from './DashboardTableCustomer';

export const DashboardTableBatchHeader = observer(function <
  A extends DashboardTableBaseAsset,
  B extends DashboardTableBaseBatch<A>,
>({ batch, actions, opened }: { batch: B; actions: ReactNode; opened: boolean }) {
  const { t } = useTranslation();

  return (
    <>
      <DashboardTableFirstColumn>
        <DashboardTableChevron opened={opened} />
        <DateFormatted format={DATE_FORMAT} date={batch.date} />
      </DashboardTableFirstColumn>

      <DashboardTableSummary>
        <strong>{batch.categories}</strong> ({t('{{count}} assets', { count: batch.assets.length })})
      </DashboardTableSummary>

      {batch.customerNames && <DashboardTableCustomer>{batch.customerNames}</DashboardTableCustomer>}

      {actions}
    </>
  );
});
