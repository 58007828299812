import './DashboardIssuedChart.scss';
import { ActiveElement } from 'chart.js';
import { RoutePath } from '../../enums/RoutePath';
import { DashboardIssuedChartAsset } from './DashboardIssuedChartAsset';
import { CoreChartOptions } from 'chart.js/dist/types';
import { useContext } from 'react';
import { DashboardContext } from '../DashboardContext';
import { useNavigate } from 'react-router-dom';
import { assetsInitialFilters } from '../../assets/assetsInitialFilters';

export function useDashboardIssuedChartClickHandler(
  assets: DashboardIssuedChartAsset[],
): CoreChartOptions<'bar'>['onClick'] {
  const navigate = useNavigate();
  const { issuedChartState } = useContext(DashboardContext);

  return (_, [element]: ActiveElement[]) => {
    if (element) {
      const asset = assets[element.index];

      if (asset) {
        assetsInitialFilters.customers = issuedChartState.customers;
        assetsInitialFilters.subcategory = asset.title;

        navigate(RoutePath.Assets);
      }
    }
  };
}
