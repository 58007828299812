import { action, makeObservable, observable } from 'mobx';
import { Deferred } from '../../../helpers/Deferred';
import { secondInterval } from '../../../hooks/secondInterval';
import { Subscription } from 'rxjs';

export class DashboardTableCancelCountdown {
  public readonly timestamp = Date.now();
  public readonly promise: Promise<void>;

  private readonly subscription: Subscription;
  private readonly deferred: Deferred;

  constructor(public duration = 5_000) {
    this.deferred = new Deferred();
    this.promise = this.deferred.promise;

    this.subscription = secondInterval.subscribe(() => {
      if (this.getSeconds() === 0) {
        this.subscription.unsubscribe();
        this.deferred.resolve();
      }
    });
  }

  get inProgress(): boolean {
    return !this.subscription.closed;
  }

  getSeconds(): number {
    return Math.max(0, Math.round((this.timestamp + this.duration - Date.now()) / 1000));
  }

  stop() {
    this.subscription.unsubscribe();
    this.deferred.reject();
  }
}

export class DashboardTableCancelable {
  cancelCountdown: DashboardTableCancelCountdown | null = null;

  protected constructor() {
    makeObservable(this, {
      cancelCountdown: observable.ref,
      startCancelCountdown: action,
      stopCancelCountdown: action,
      setCancelCountdown: action,
    });
  }

  startCancelCountdown(): DashboardTableCancelCountdown {
    return (this.cancelCountdown = new DashboardTableCancelCountdown());
  }

  stopCancelCountdown() {
    if (this.cancelCountdown) {
      this.cancelCountdown.stop();
      this.cancelCountdown = null;
    }
  }

  setCancelCountdown(value: DashboardTableCancelCountdown | null) {
    this.cancelCountdown = value;
  }
}
