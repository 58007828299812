import { FC, ReactNode } from 'react';
import './DashboardTableHeader.scss';

export const DashboardTableHeader: FC<{
  title: ReactNode;
  description: ReactNode;
}> = ({ title, description }) => {
  return (
    <div className="DashboardTableHeader">
      <h3 className="title">{title}</h3>
      <div className="description">{description}</div>
    </div>
  );
};
