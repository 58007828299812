import React, { Dispatch, useContext, useMemo, useState } from 'react';
import Select from 'react-select';
import { DashboardContext } from '../DashboardContext';
import { ChevronDown, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { selectCommonStyles } from '../../components/selectCommonStyles';
import { DashboardDialogAssetOptionsFactory } from './DashboardDialogAssetOptionsFactory';
import { DashboardDialogSelectOption } from "./types/DashboardDialogSelectOption";

export function DashboardDialogsAssetsSelect({ onChange }: { onChange: Dispatch<DashboardDialogSelectOption[]> }) {
  const { t } = useTranslation();
  const { assets } = useContext(DashboardContext);
  const [selectedOptions, setSelectedOptions] = useState<DashboardDialogSelectOption[]>(() => []);
  const options = useMemo(
    () => new DashboardDialogAssetOptionsFactory(assets.data || [], selectedOptions).getOptions(),
    [assets.data, selectedOptions],
  );

  return (
    <>
      <Select
        options={options}
        onChange={(options) => {
          const array = options.slice();

          setSelectedOptions(array);
          onChange(array);
        }}
        isMulti
        isRtl={process.env.REACT_APP_LANG === 'he'}
        placeholder={t('Select assets')}
        components={{
          IndicatorSeparator: null,
          ClearIndicator: ({ innerProps }) => {
            return (
              <div {...innerProps}>
                <X size={16} />
              </div>
            );
          },
          DropdownIndicator: ({ innerProps }) => {
            return (
              <div {...innerProps} className="px-1">
                <ChevronDown size={16} />
              </div>
            );
          },
        }}
        styles={selectCommonStyles}
      />
    </>
  );
}
