import { useContext, useEffect } from 'react';
import { DashboardContext } from '../DashboardContext';

export function useDashboardLeaveConfirm() {
  const { unassignedBatchesFactory, returnedBatchesFactory } = useContext(DashboardContext);

  useEffect(() => {
    const callback = (event: BeforeUnloadEvent) => {
      const processing =
        unassignedBatchesFactory.batches.some((batch) => batch.processing) ||
        returnedBatchesFactory.batches.some((batch) => batch.processing);

      if (processing) {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to leave this page?';
      }
    };

    window.addEventListener('beforeunload', callback);

    return () => {
      window.removeEventListener('beforeunload', callback);
    };
  }, [unassignedBatchesFactory, returnedBatchesFactory]);
}
