import { useContext, useMemo } from 'react';
import { DashboardContext } from '../DashboardContext';
import { FormSelectOption } from '../../components/form/FormSelect';
import { Customer } from '../../customers/dto/Customer';

export type DashboardIssuedChartOption = FormSelectOption<number> & {
  customer: Customer;
};

export function useDashboardIssuedChartOptions(): DashboardIssuedChartOption[] {
  const { data } = useContext(DashboardContext).customers;

  return useMemo(
    () =>
      data
        ? data.map((customer) => ({
            value: customer.id,
            label: customer.name,
            customer,
          }))
        : [],
    [data],
  );
}
