import React, { FC } from 'react';
import './AssetsMaintenance.scss';
import { FormField } from '../components/form/FormField';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const AssetsMaintenance: FC = () => {
  const { t } = useTranslation();

  return (
    <FormField
      className="AssetsMaintenance"
      label={t('Maintenance').toString()}
      name="maintenance"
      render={({ invalid, controlAttrs }) => <Form.Check {...controlAttrs} isInvalid={invalid} />}
    />
  );
};
