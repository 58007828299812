import React, { useMemo } from 'react';
import { createStringFilterFunction } from '../../components/table/functions/createStringFilterFunction';
import { AdminTableInputFilter } from '../../components/table/filters/AdminTableInputFilter';
import { AdminTableColumn } from '../../components/table/interfaces/AdminTableColumn';
import { Transaction } from '../dto/Transaction';
import { AdminDate } from '../../components/table/AdminDate';
import { createDateFilterFunction } from '../../components/table/functions/createDateFilterFunction';
import { AdminTableDateFilter } from '../../components/table/filters/AdminTableDateFilter';
import { AdminBooleanCell } from '../../components/table/AdminBooleanCell';
import { createBooleanFilterFunction } from '../../components/table/functions/createBooleanFilterFunction';
import { AdminTableBooleanFilter } from '../../components/table/filters/AdminTableBooleanFilter';
import { AdminIsoDate } from '../../components/table/AdminIsoDate';
import { useTranslation } from 'react-i18next';

export function useTransactionColumns(): AdminTableColumn<Transaction>[] {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        title: t('Asset').toString(),
        predicate: (item) => item.assetGuid,
        sortingPredicate: (item) => item.assetGuid,
        filterConfig: {
          filterFn: createStringFilterFunction((item) => item.assetGuid),
          component: AdminTableInputFilter,
        },
      },
      {
        title: t('Customer').toString(),
        predicate: (item) => item.customerName,
        sortingPredicate: (item) => item.customerName,
        filterConfig: {
          filterFn: createStringFilterFunction((item) => item.customerName),
          component: AdminTableInputFilter,
        },
      },
      {
        title: t('Returned').toString(),
        predicate: (item) => <AdminBooleanCell value={!!item.returnedAt} />,
        sortingPredicate: (item) => !!item.returnedAt,
        filterConfig: {
          filterFn: createBooleanFilterFunction((item) => !!item.returnedAt),
          component: AdminTableBooleanFilter,
        },
        style: {
          width: 100,
        },
      },
      {
        title: t('Returned at').toString(),
        predicate: (item) => <AdminIsoDate isoDate={item.returnedAt} otherwise="-" />,
        sortingPredicate: (item) => item.returnedAt,
        filterConfig: {
          filterFn: createDateFilterFunction((item) => item.returnedAt),
          component: AdminTableDateFilter,
        },
        style: {
          width: 160,
        },
      },
      {
        title: t('Created at'),
        predicate: (item) => <AdminDate date={item.createdAt} />,
        sortingPredicate: (item) => item.createdAt,
        filterConfig: {
          filterFn: createDateFilterFunction((item) => item.createdAt),
          component: AdminTableDateFilter,
        },
        style: {
          width: 160,
        },
      },
    ],
    [t],
  );
}
