import { Dispatch, FC, SelectHTMLAttributes, useContext } from 'react';
import { DashboardContext } from '../../DashboardContext';
import { Customer } from '../../../customers/dto/Customer';
import './DashboardTableUnassignedSelect.scss';

export const DashboardTableUnassignedSelect: FC<
  SelectHTMLAttributes<HTMLSelectElement> & {
    onCustomerSelect: Dispatch<Customer>;
    placeholder: string;
  }
> = ({ placeholder, onCustomerSelect, ...attrs }) => {
  const { data = [] } = useContext(DashboardContext).customers;

  return (
    <select
      className="DashboardTableUnassignedSelect"
      onChange={(event) => {
        const id = parseInt(event.target.value, 10);

        if (Number.isFinite(id)) {
          const customer = data.find((customer) => customer.id === id);

          if (customer) {
            onCustomerSelect(customer);
          }
        }
      }}
      defaultValue=""
      {...attrs}
    >
      <option disabled value="">
        {placeholder}
      </option>

      {data.map((customer) => (
        <option value={customer.id} key={customer.id}>
          {customer.name}
        </option>
      ))}
    </select>
  );
};
