import { FC, useContext } from 'react';
import '../DashboardTable.scss';
import { useTranslation } from 'react-i18next';
import { DashboardContext } from '../../DashboardContext';
import { DashboardTableHeader } from '../DashboardTableHeader';
import { observer } from 'mobx-react-lite';
import { DashboardTableBatches } from '../DashboardTableBatches';
import { DashboardTableUnassignedAssetActions } from './DashboardTableUnassignedAssetActions';
import { DashboardTableUnassignedBatchActions } from './DashboardTableUnassignedBatchActions';

export const DashboardTableUnassigned: FC = observer(() => {
  const { t } = useTranslation();
  const { batches } = useContext(DashboardContext).unassignedBatchesFactory;

  return (
    <>
      <DashboardTableHeader
        title={t('Unassigned assets')}
        description={t('Assets that have left the warehouse but are not assigned to anyone.')}
      />

      <DashboardTableBatches
        batches={batches}
        BatchActions={DashboardTableUnassignedBatchActions}
        AssetActions={DashboardTableUnassignedAssetActions}
      />
    </>
  );
});
