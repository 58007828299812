import { FC } from 'react';
import './Dashboard.scss';
import { DashboardContextProvider } from './DashboardContext';
import { DashboardReports } from './reports/DashboardReports';
import { DashboardTable } from './table/DashboardTable';
import { DashboardButtons } from './buttons/DashboardButtons';
import { observer } from 'mobx-react-lite';
import { DashboardCharts } from './charts/DashboardCharts';

export const Dashboard: FC = observer(() => {
  return (
    <DashboardContextProvider>
      <div className="Dashboard">
        <DashboardCharts />
        <DashboardReports />
        <DashboardTable />
        <DashboardButtons />
      </div>
    </DashboardContextProvider>
  );
});
