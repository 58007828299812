import React, { useMemo } from 'react';
import { AdminTableColumn } from '../../components/table/interfaces/AdminTableColumn';
import { Asset } from '../dto/Asset';
import { useTranslation } from 'react-i18next';
import { TableSelectItem } from '../../components/table/TableSelectItem';

export function useAssetSelectColumn(): AdminTableColumn<Asset> {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      title: null,
      component: TableSelectItem,
      predicate: (item) => (
        <TableSelectItem
          item={item}
          disablingReason={t('Cannot perform action on assigned and unassigned assets').toString()}
        />
      ),
      style: {
        width: 40,
        padding: 0,
      },
    }),
    [t],
  );
}
