import { useContext, useEffect } from 'react';
import { AdminTableContext } from '../../components/table/contexts/AdminTableContext';
import { AssetsTakenColumnConfig, AssetsTakenFilterValue } from './AssetsTakenColumnConfig';
import { Asset } from '../dto/Asset';

export function useAssetsTakenColumnFilterSetter({
  filterValue,
  filterFn,
}: {
  filterValue: AssetsTakenFilterValue;
  filterFn: AssetsTakenColumnConfig['filterFn'];
}): void {
  const { setFilterFunctions } = useContext(AdminTableContext);

  useEffect(() => {
    if (typeof filterValue === 'boolean' || Array.isArray(filterValue)) {
      const fn = (asset: Asset) => filterFn(asset, filterValue);

      setFilterFunctions((items) => items.concat(fn));

      return () => {
        setFilterFunctions((items) => items.filter((item) => item !== fn));
      };
    }
  }, [filterValue, filterFn, setFilterFunctions]);
}
