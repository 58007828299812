import { Dispatch, DispatchWithoutAction, useMemo } from 'react';
import { ApiPath } from '../../enums/ApiPath';
import { useDeleteItem } from '../../hooks/useDeleteItem';
import { Asset } from '../dto/Asset';
import { api } from '../../functions/api';
import { useDuplicateAsset } from './useDuplicateAsset';
import { useShowAssetOnMap } from '../../hooks/useShowAssetOnMap';
import { AdminTableActionsItem } from '../../components/table/interfaces/AdminTableActionsItem';
import { useTranslation } from 'react-i18next';

export function useAssetsActions({
  setEditableAsset,
  reload,
}: {
  setEditableAsset: Dispatch<Asset | null>;
  reload: DispatchWithoutAction;
}) {
  const { t } = useTranslation();
  const deleteAsset = useDeleteItem<Asset>((item) =>
    api({
      url: ApiPath.Asset.replace(':id', item.id.toString()),
      method: 'delete',
    }),
  );
  const duplicateAsset = useDuplicateAsset();
  const showAssetOnMap = useShowAssetOnMap();

  return useMemo<AdminTableActionsItem<Asset>[]>(
    () => [
      {
        title: t('Show on map'),
        onClick: (asset) => showAssetOnMap(asset),
      },
      {
        title: t('Edit'),
        onClick: setEditableAsset,
      },
      {
        title: t('Duplicate'),
        onClick: (asset) => duplicateAsset(asset).then(() => reload()),
        isDisabled: ()=> true,
      },
      {
        title: t('Delete'),
        onClick: (item) => {
          if (window.confirm(t('Are you sure you want to delete?').toString())) {
            deleteAsset(item).then(() => reload());
          }
        },
      },
    ],
    [showAssetOnMap, deleteAsset, duplicateAsset, t, reload, setEditableAsset],
  );
}
