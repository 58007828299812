import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useEffect, useMemo, useState } from 'react';
import { DashboardTableContentType } from './enums/DashboardTableContentType';
import { Customer } from '../customers/dto/Customer';
import { Asset } from '../assets/dto/Asset';
import { AsyncState, useAsync } from 'react-async';
import { useCustomers } from '../hooks/useCustomers';
import { useAssets } from '../assets/hooks/useAssets';
import { useApiPromiseFn } from '../hooks/useApiPromiseFn';
import { ApiPath } from '../enums/ApiPath';
import { DashboardStatistics } from './types/DashboardStatistics';
import { DashboardUnassignedAssets } from './types/DashboardUnassignedAssets';
import { DashboardReturnedAssets } from './types/DashboardReturnedAssets';
import { useInterval } from 'ahooks';
import { DashboardTableBatchesFactory } from './table/DashboardTableBatchesFactory';
import { DashboardTableUnassignedBatch } from './table/unassigned/DashboardTableUnassignedBatch';
import { DashboardTableReturnedBatch } from './table/returned/DashboardTableReturnedBatch';
import { DashboardReturnedAsset } from './types/DashboardReturnedAsset';
import { DashboardUnassignedAsset } from './types/DashboardUnassignedAsset';
import { DashboardTableUnassignedAsset } from './table/unassigned/DashboardTableUnassignedAsset';
import { DashboardTableReturnedAsset } from './table/returned/DashboardTableReturnedAsset';
import { DashboardLocationsChartResponse } from './charts/DashboardLocationsChartResponse';
import { DashboardIssuedChartResponse } from './charts/DashboardIssuedChartResponse';

import { DashboardIssuedChartState } from './charts/DashboardIssuedChartState';
import { DashboardLocationsChartState } from './charts/DashboardLocationsChartState';
import { observer } from 'mobx-react-lite';

interface DashboardContextValue {
  tableContentType: DashboardTableContentType;
  setTableContentType: Dispatch<SetStateAction<DashboardTableContentType>>;
  issuedChartState: DashboardIssuedChartState;
  issuedAsyncState: AsyncState<DashboardIssuedChartResponse>;
  locationsChartState: DashboardLocationsChartState;
  locationsAsyncState: AsyncState<DashboardLocationsChartResponse>;
  assets: AsyncState<Asset[]>;
  customers: AsyncState<Customer[]>;
  statistics: AsyncState<DashboardStatistics>;
  unassignedBatchesFactory: DashboardTableBatchesFactory<
    DashboardUnassignedAsset,
    DashboardTableUnassignedAsset,
    DashboardTableUnassignedBatch
  >;
  returnedBatchesFactory: DashboardTableBatchesFactory<
    DashboardReturnedAsset,
    DashboardTableReturnedAsset,
    DashboardTableReturnedBatch
  >;
  tableContainerElement: HTMLDivElement | null;
  setTableContainerElement: Dispatch<SetStateAction<HTMLDivElement | null>>;
}

export const DashboardContext = createContext({} as DashboardContextValue);

function useDashboardContextValue(): DashboardContextValue {
  const [tableContentType, setTableContentType] = useState(DashboardTableContentType.Unassigned);
  const [tableContainerElement, setTableContainerElement] = useState<HTMLDivElement | null>(null);
  const assets = useAssets();
  const customers = useCustomers();
  const statistics = useAsync<DashboardStatistics>({
    promiseFn: useApiPromiseFn(ApiPath.DashboardStatistics),
  });
  const issuedChartState = useMemo(() => new DashboardIssuedChartState(), []);
  const issuedAsyncState = useAsync<DashboardIssuedChartResponse>({
    promiseFn: issuedChartState.promiseFn,
    watch: issuedChartState.customers,
  });
  const locationsChartState = useMemo(() => new DashboardLocationsChartState(), []);
  const locationsAsyncState = useAsync<DashboardLocationsChartResponse>({
    promiseFn: locationsChartState.promiseFn,
    watch: locationsChartState.subcategories,
  });
  const unassignedAssets = useAsync<DashboardUnassignedAssets>({
    promiseFn: useApiPromiseFn(ApiPath.DashboardUnassignedAssets),
  });
  const returnedAssets = useAsync<DashboardReturnedAssets>({
    promiseFn: useApiPromiseFn(ApiPath.DashboardReturnedAssets),
  });
  const unassignedBatchesFactory = useMemo(
    () =>
      new DashboardTableBatchesFactory<
        DashboardUnassignedAsset,
        DashboardTableUnassignedAsset,
        DashboardTableUnassignedBatch
      >((date, assets, remove) => new DashboardTableUnassignedBatch(date, assets, remove)),
    [],
  );
  const returnedBatchesFactory = useMemo(
    () =>
      new DashboardTableBatchesFactory<
        DashboardReturnedAsset,
        DashboardTableReturnedAsset,
        DashboardTableReturnedBatch
      >((date, assets, remove) => new DashboardTableReturnedBatch(date, assets, remove)),
    [],
  );

  useEffect(
    () => unassignedAssets.data && unassignedBatchesFactory.updateAssets(unassignedAssets.data.assets),
    [unassignedAssets.data, unassignedBatchesFactory],
  );

  useEffect(
    () => returnedAssets.data && returnedBatchesFactory.updateAssets(returnedAssets.data.assets),
    [returnedAssets.data, returnedBatchesFactory],
  );

  useInterval(() => {
    assets.reload();
    customers.reload();
    statistics.reload();
    unassignedAssets.reload();
    returnedAssets.reload();
    issuedAsyncState.reload();
    locationsAsyncState.reload();
  }, 60_000);

  return {
    tableContentType,
    setTableContentType,
    assets,
    customers,
    statistics,
    unassignedBatchesFactory,
    returnedBatchesFactory,
    issuedChartState,
    issuedAsyncState,
    locationsChartState,
    locationsAsyncState,
    tableContainerElement,
    setTableContainerElement,
  };
}

export const DashboardContextProvider: FC<PropsWithChildren> = observer(({ children }) => (
  <DashboardContext.Provider value={useDashboardContextValue()}>{children}</DashboardContext.Provider>
));
