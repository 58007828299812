import { SelectComponentsConfig } from 'react-select/dist/declarations/src/components';
import { ChevronDown, X } from 'lucide-react';

export const selectCommonComponents: SelectComponentsConfig<any, any, any> = {
  IndicatorSeparator: null,
  ClearIndicator: ({ innerProps }) => {
    return (
      <div {...innerProps}>
        <X size={16} />
      </div>
    );
  },
  DropdownIndicator: ({ innerProps }) => {
    return (
      <div {...innerProps} className="px-1">
        <ChevronDown size={16} />
      </div>
    );
  },
};
