import { useTableSelectContext } from '../../components/table/TableSelectContext';
import { Asset } from '../dto/Asset';
import { useMemo } from 'react';

export function useAssetsMultipleReassign(): boolean {
  const { selectedItems } = useTableSelectContext<Asset>();

  return useMemo(() => {
    for (const selectedItem of selectedItems) {
      if (selectedItem.takenBy) {
        return true;
      }
    }

    return false;
  }, [selectedItems]);
}
