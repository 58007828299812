import { FC } from 'react';
import { ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';
import './DashboardTableChevron.scss';

export const DashboardTableChevron: FC<{ opened: boolean }> = ({ opened }) => {
  const Icon = opened ? ChevronDown : process.env.REACT_APP_LANG === 'he' ? ChevronLeft : ChevronRight;

  return (
    <div className="DashboardTableChevron">
      <Icon size={16} strokeWidth={1.75} />
    </div>
  );
};
