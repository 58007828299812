import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

export type TableSelectConditionFn<T> = (item: T, selectedItems: Set<T>) => boolean;

export interface TableSelectContextValue<T> {
  allItems: T[];
  selectedItems: Set<T>;
  setSelectedItems: Dispatch<SetStateAction<Set<T>>>;
  selectConditionFn?: TableSelectConditionFn<T>;
}

export const TableSelectContext = createContext({} as TableSelectContextValue<any>);

export function useTableSelectContextValue<T>(
  allItems: T[],
  selectConditionFn?: TableSelectConditionFn<T>,
): TableSelectContextValue<T> {
  const [selectedItems, setSelectedItems] = useState<Set<T>>(() => new Set());

  useEffect(() => {
    setSelectedItems(new Set());
  }, [allItems]);

  return {
    allItems,
    selectedItems,
    setSelectedItems,
    selectConditionFn,
  };
}

export function useTableSelectContext<T>(): TableSelectContextValue<T> {
  return useContext(TableSelectContext);
}
