import { DashboardTableBaseAsset } from '../types/DashboardTableBaseAsset';
import { DispatchWithoutAction } from 'react';
import { action, makeObservable, reaction } from 'mobx';
import { DashboardUnassignedAsset } from '../../types/DashboardUnassignedAsset';
import { assignAssets } from '../assignAssets';
import { Customer } from '../../../customers/dto/Customer';

export class DashboardTableUnassignedAsset extends DashboardTableBaseAsset {
  constructor(asset: DashboardUnassignedAsset, remove: DispatchWithoutAction) {
    super(asset, remove);

    makeObservable(this, {
      assign: action,
    });

    reaction(
      () => this.cancelCountdown,
      (value) => {
        if (value === null) {
          this.setTakenBy(null);
        }
      },
    );
  }

  async assign(customer: Customer) {
    this.setTakenBy(customer.name);

    await this.startProcessing(() => assignAssets([this.id], customer.id)).finally(() => {
      this.setCancelCountdown(null);
    });
  }
}
