import { FC, useContext, useMemo } from 'react';
import { DashboardChart } from './DashboardChart';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { DashboardChartSelect } from './DashboardChartSelect';
import { colors } from '../../colors';
import './DashboardIssuedChart.scss';
import { useDashboardIssuedChartOptions } from './useDashboardIssuedChartOptions';
import { DashboardContext } from '../DashboardContext';
import { observer } from 'mobx-react-lite';
import { ActiveElement, Chart } from 'chart.js';
import { useDashboardIssuedChartClickHandler } from './useDashboardIssuedChartClickHandler';

export const DashboardIssuedChart: FC = observer(() => {
  const { t } = useTranslation();
  const {
    issuedChartState,
    issuedAsyncState: { data },
  } = useContext(DashboardContext);
  const options = useDashboardIssuedChartOptions();
  const assets = useMemo(
    () =>
      data
        ? data.assets.sort((site, other) => (site.count === other.count ? 0 : site.count > other.count ? -1 : 1))
        : [],
    [data],
  );
  const clickHandler = useDashboardIssuedChartClickHandler(assets);

  return (
    <DashboardChart
      className="DashboardIssuedChart"
      title={t('Issued assets').toString()}
      filter={
        <DashboardChartSelect
          options={options}
          onChange={(selectedOptions) =>
            issuedChartState.setCustomers(selectedOptions.map((selectedOption) => selectedOption.customer))
          }
          placeholder={t('Select customer')}
        />
      }
    >
      <Bar
        options={{
          onClick: clickHandler,
          layout: {
            autoPadding: false,
            padding: {
              top: 20,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: true,
              padding: 0,
              align: 'top',
              textAlign: 'right',
              anchor: 'end',
            },
          },
          onHover(_, elements: ActiveElement[], chart: Chart) {
            if (elements.length === 1) {
              chart.canvas.style.cursor = 'pointer';
            } else {
              chart.canvas.style.cursor = 'default';
            }
          },
        }}
        data={{
          labels: assets.map((asset) => asset.title),
          datasets: [
            {
              label: t('Assets').toString(),
              data: assets.map((asset) => asset.count),
              backgroundColor: colors,
            },
          ],
        }}
      />
    </DashboardChart>
  );
});
