import { DashboardTableCancelCountdown } from '../dashboard/table/types/DashboardTableCancelable';
import { useEffect, useState } from 'react';
import { secondInterval } from './secondInterval';

export function useCountdownSeconds(countdown: DashboardTableCancelCountdown) {
  const [seconds, setSeconds] = useState(() => countdown.getSeconds());

  useEffect(() => {
    setSeconds(countdown.getSeconds());

    const subscription = secondInterval.subscribe(() => {
      setSeconds(countdown.getSeconds());
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [countdown]);

  return seconds;
}
