import { FC, useContext } from 'react';
import { DashboardReport } from './DashboardReport';
import { useTranslation } from 'react-i18next';
import './DashboardReports.scss';
import { DashboardContext } from '../DashboardContext';
import { DashboardTableContentType } from '../enums/DashboardTableContentType';
import { DashboardReportWarningValue } from './DashboardReportWarningValue';
import { observer } from 'mobx-react-lite';
import { ExternalLink } from 'lucide-react';
import { RoutePath } from '../../enums/RoutePath';

export const DashboardReports: FC = observer(() => {
  const { t } = useTranslation();
  const {
    setTableContentType,
    tableContentType,
    assets,
    statistics,
    unassignedBatchesFactory,
    returnedBatchesFactory,
    tableContainerElement,
  } = useContext(DashboardContext);

  return (
    <div className="DashboardReports">
      <DashboardReport title={t('Total assets')} value={assets.data ? assets.data.length : 0} />
      <DashboardReport title={t('In stock')} value={statistics.data ? statistics.data.inStock : 0} />
      <DashboardReport title={t('Issued')} value={statistics.data ? statistics.data.issued : 0} />

      <DashboardReport
        title={t('Unassigned')}
        value={<DashboardReportWarningValue value={unassignedBatchesFactory.assetsCount} critical />}
        onClick={() => {
          setTableContentType(DashboardTableContentType.Unassigned);
          tableContainerElement?.scrollIntoView();
        }}
        active={tableContentType === DashboardTableContentType.Unassigned}
      />

      <DashboardReport
        title={t('Returned')}
        value={<DashboardReportWarningValue value={returnedBatchesFactory.assetsCount} critical />}
        onClick={() => {
          setTableContentType(DashboardTableContentType.Returned);
          tableContainerElement?.scrollIntoView();
        }}
        active={tableContentType === DashboardTableContentType.Returned}
      />

      <DashboardReport
        title={t('Maintenance')}
        value={<DashboardReportWarningValue value={statistics.data ? statistics.data.maintenance : 0} />}
        icon={<ExternalLink size={16} strokeWidth={1.75} />}
        to={RoutePath.Assets + '?maintenance=true'}
      />
    </div>
  );
});
