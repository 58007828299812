import React, { FC, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ModalContext from 'react-bootstrap/ModalContext';

export const FormModalFooter: FC<{ disabled?: boolean }> = ({ disabled = false }) => {
  const { t } = useTranslation();
  const { onHide } = useContext(ModalContext);

  return (
    <Modal.Footer className="pt-0">
      <Button type="button" variant="outline-primary" onClick={onHide}>
        {t('Cancel')}
      </Button>

      <Button type="submit" variant="primary" disabled={disabled}>
        {t('Save')}
      </Button>
    </Modal.Footer>
  );
};
