import { observer } from 'mobx-react-lite';
import { DashboardTableBaseBatch } from './types/DashboardTableBaseBatch';
import { DashboardTableBatch } from './DashboardTableBatch';
import { DashboardTableEmpty } from './DashboardTableEmpty';
import { DashboardTableBaseAsset } from './types/DashboardTableBaseAsset';
import { ComponentType } from 'react';
import './DashboardTableBatches.scss';

export const DashboardTableBatches = observer(function <
  A extends DashboardTableBaseAsset,
  B extends DashboardTableBaseBatch<A>,
>({
  batches,
  BatchActions,
  AssetActions,
}: {
  batches: B[];
  BatchActions: ComponentType<{ batch: B }>;
  AssetActions: ComponentType<{ batch: B; asset: A }>;
}) {
  return (
    <div className="DashboardTableBatches">
      {batches.length ? (
        batches.map((batch) => (
          <DashboardTableBatch<A, B>
            batch={batch}
            actions={<BatchActions batch={batch} />}
            AssetActions={AssetActions}
            key={batch.dateString}
          />
        ))
      ) : (
        <DashboardTableEmpty />
      )}
    </div>
  );
});
