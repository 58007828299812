import { useState } from 'react';
import { assetsInitialFilters } from '../assetsInitialFilters';
import { AssetsTakenColumnConfig } from './AssetsTakenColumnConfig';

export function useAssetsTakenColumnFilterInitialValue(): AssetsTakenColumnConfig['initialValue'] {
  return useState(() => {
    const customers = assetsInitialFilters.extractCustomers();

    return customers ? customers.map((customer) => customer.name) : null;
  })[0];
}
