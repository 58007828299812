import { FC } from 'react';
import { Asset } from './dto/Asset';
import './AssetImageColumn.scss';
import { API_URL } from '../constants';

interface Props {
  item: Asset;
}

export const AssetImageColumn: FC<Props> = ({ item }) => {
  return item.imageUrl ? (
    <a
      className="AssetImageColumn"
      href={API_URL + item.imageUrl}
      target="_blank"
      style={{
        backgroundImage: `url(${API_URL + item.imageUrl})`,
      }}
      rel="noreferrer"
    />
  ) : null;
};
