import { Renderable, ValueOrFunction } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export function useSavingToastMessages<T>(): {
  loading: Renderable;
  success: ValueOrFunction<Renderable, T>;
  error: ValueOrFunction<Renderable, any>;
} {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      loading: t('Saving'),
      success: t('Saved successfully'),
      error: t('Error while saving'),
    }),
    [t],
  );
}
