import React, { FC, useContext } from 'react';
import { FormFieldContext } from '../components/form/FormFieldContext';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import { AssetsMultipleAssignFromData } from './dto/AssetsMultipleAssignFromData';
import { useAssetsMultipleAssignOptions } from './useAssetsMultipleAssignOptions';

export const AssetsMultipleAssignFromCustomer: FC<{ reassign: boolean }> = ({ reassign }) => {
  const form = useFormContext<AssetsMultipleAssignFromData>();
  const options = useAssetsMultipleAssignOptions(reassign);
  const { controlId } = useContext(FormFieldContext);

  return (
    <Controller
      name="customer"
      control={form.control}
      render={({ field }) => <Select {...field} placeholder={null} inputId={controlId} options={options} />}
    />
  );
};
