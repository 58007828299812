import React, { FC, useContext } from 'react';
import { AdminTableContext } from './contexts/AdminTableContext';
import './AdminTableFilters.scss';

export const AdminTableFilters: FC = () => {
  const { columns, actions } = useContext(AdminTableContext);

  return (
    <thead className="AdminTableFilters">
      <tr>
        {columns.map(({ filterConfig }, index) => (
          <td key={index}>
            {filterConfig && (
              <filterConfig.component filterFn={filterConfig.filterFn} initialValue={filterConfig.initialValue} />
            )}
          </td>
        ))}

        {actions && actions.length > 0 && <td />}
      </tr>
    </thead>
  );
};
