import { AssetDto } from '../dto/AssetDto';

export function createAssetFormData(asset: AssetDto) {
  const data = new FormData();
  const image = asset.image?.item(0);

  data.set('title', asset.title);
  data.set('guid', asset.guid);

  asset.alerts.forEach((alert, index) => {
    if (alert.id) {
      data.set(`alerts[${index}][id]`, alert.id.toString());
    }

    data.set(`alerts[${index}][message]`, alert.message);
    data.set(`alerts[${index}][period]`, alert.period.toString());
    data.set(`alerts[${index}][startedAt]`, alert.startedAt);
  });
  data.set('maintenance', asset.maintenance ? 'true' : 'false');
  asset.category && data.set('category', asset.category);
  asset.subcategory && data.set('subcategory', asset.subcategory);
  asset.notes && data.set('notes', asset.notes);
  image && data.set('image', image, image.name);

  return data;
}
