import React, { useMemo } from 'react';
import { createBooleanFilterFunction } from '../../components/table/functions/createBooleanFilterFunction';
import { AdminTableBooleanFilter } from '../../components/table/filters/AdminTableBooleanFilter';
import { AdminTableColumn } from '../../components/table/interfaces/AdminTableColumn';
import { Asset } from '../dto/Asset';
import { useTranslation } from 'react-i18next';
import { AdminBooleanCell } from '../../components/table/AdminBooleanCell';
import { useAssetsMaintenanceFilterInitialValue } from './useAssetsMaintenanceFilterInitialValue';

export function useAssetMaintenanceColumn(): AdminTableColumn<Asset> {
  const { t } = useTranslation();
  const initialValue = useAssetsMaintenanceFilterInitialValue();

  return useMemo(
    () => ({
      title: t('Maintenance').toString(),
      predicate: (item) => <AdminBooleanCell value={!!item.maintenance} />,
      sortingPredicate: (item) => !!item.maintenance,
      filterConfig: {
        filterFn: createBooleanFilterFunction((item) => !!item.maintenance),
        component: AdminTableBooleanFilter,
        initialValue,
      },
      style: {
        width: 120,
      },
    }),
    [t, initialValue],
  );
}
