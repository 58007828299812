import { useTranslation } from 'react-i18next';
import { useCustomers } from '../../hooks/useCustomers';
import { useMemo } from 'react';
import { AssetsTakenColumnFilterOptions } from './AssetsTakenColumnFilterOptions';
import { FormSelectOption } from '../../components/form/FormSelect';

export type AssetsTakenColumnFilterGroupedOptions = {
  allOption: FormSelectOption<null>;
  takenOption: FormSelectOption<true>;
  inStockOption: FormSelectOption<false>;
  customerOptions: FormSelectOption[];
};

export function useAssetsTakenColumnFilterGroupedOptions(): AssetsTakenColumnFilterGroupedOptions {
  const takenOption = useAssetsTakenColumnFilterTakenOption();
  const allOption = useAssetsTakenColumnFilterAllOption();
  const inStockOption = useAssetsTakenColumnFilterInStockOption();
  const customerOptions = useAssetsTakenColumnFilterCustomerOptions();

  return {
    allOption,
    takenOption,
    inStockOption,
    customerOptions,
  };
}

export function useAssetsTakenColumnFilterAllOption(): FormSelectOption<null> {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      label: t('Show all').toString(),
      value: null,
    }),
    [t],
  );
}

export function useAssetsTakenColumnFilterTakenOption(): FormSelectOption<true> {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      label: t('Taken').toString(),
      value: true,
    }),
    [t],
  );
}

export function useAssetsTakenColumnFilterInStockOption(): FormSelectOption<false> {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      label: t('In stock').toString(),
      value: false,
    }),
    [t],
  );
}

export function useAssetsTakenColumnFilterCustomerOptions(): FormSelectOption[] {
  const { data } = useCustomers();

  return useMemo(
    () =>
      data
        ? data.map((customer) => ({
            label: customer.name,
            value: customer.name,
          }))
        : [],
    [data],
  );
}

export function useAssetsTakenColumnFilterOptions({
  allOption,
  takenOption,
  inStockOption,
  customerOptions,
}: AssetsTakenColumnFilterGroupedOptions): AssetsTakenColumnFilterOptions {
  const { t } = useTranslation();

  return useMemo(() => {
    const options: AssetsTakenColumnFilterOptions = [
      allOption,
      {
        label: t('State').toString(),
        options: [takenOption, inStockOption],
      },
    ];

    if (customerOptions.length) {
      options.push({
        label: t('Customers').toString(),
        options: customerOptions,
      });
    }

    return options;
  }, [t, customerOptions, allOption, takenOption, inStockOption]);
}
