import { api } from '../../functions/api';
import { ApiPath } from '../../enums/ApiPath';

export function assignAssets(assetIds: number[], customerId: number | null = null): Promise<void> {
  return api({
    url: ApiPath.DashboardTransactions,
    method: 'post',
    data: {
      assetIds,
      customerId,
    },
  });
}
