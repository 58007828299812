import classNames from 'classnames';
import React, { ChangeEvent, Dispatch, PropsWithChildren, SelectHTMLAttributes, useCallback, useMemo } from 'react';

export interface FormSelectOption<T = string> {
  value: T;
  label: string;
}

export interface FormSelectProps<T> extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'value'> {
  options: FormSelectOption<T>[];
  onValueChange?: Dispatch<T>;
  value?: T;
}

export function FormSelect<T>({
  className,
  options,
  onChange,
  onValueChange,
  value,
  ...attrs
}: PropsWithChildren<FormSelectProps<T>>) {
  const onChangeCallback = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      if (onChange) {
        onChange(event);
      }

      const index = +event.target.value;
      const option = options[index];

      if (onValueChange) {
        onValueChange(option.value);
      }
    },
    [onChange, onValueChange, options],
  );
  const indexValue = useMemo(() => options.findIndex((option) => option.value === value), [options, value]);

  return (
    <select className={classNames('FormSelect', className)} onChange={onChangeCallback} value={indexValue} {...attrs}>
      {options.map((option, index) => (
        <option value={index} key={index}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
