import React, { FC, PropsWithChildren, ReactNode, useRef, useState } from 'react';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import './Tooltip.scss';
import { Placement } from '@floating-ui/utils';

const ARROW_HEIGHT = 6;
const GAP = 4;

export const Tooltip: FC<PropsWithChildren<{ tooltip: ReactNode; placement?: Placement }>> = ({
  children,
  tooltip,
  placement,
}) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef<SVGSVGElement | null>(null);
  const { refs, floatingStyles, context } = useFloating({
    middleware: [
      offset(ARROW_HEIGHT + GAP),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    placement,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useDismiss(context),
    useRole(context, {
      role: 'tooltip',
    }),
  ]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>

      {open && (
        <FloatingPortal>
          <div
            className="Tooltip"
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
            }}
            {...getFloatingProps()}
          >
            {tooltip}
            <FloatingArrow ref={arrowRef} context={context} height={ARROW_HEIGHT} width={10} />
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
