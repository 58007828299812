import { useMemo } from 'react';
import { AssetsTakenColumnFilterOption } from './AssetsTakenColumnFilterOption';
import { AssetsTakenColumnConfig } from './AssetsTakenColumnConfig';
import { AssetsTakenColumnFilterGroupedOptions } from './useAssetsTakenColumnFilterOptions';

export function useAssetsTakenColumnFilterInitialOptions(
  initialValue: AssetsTakenColumnConfig['initialValue'],
  { allOption, takenOption, inStockOption, customerOptions }: AssetsTakenColumnFilterGroupedOptions,
): AssetsTakenColumnFilterOption[] {
  return useMemo(() => {
    const options: AssetsTakenColumnFilterOption[] = [];

    if (initialValue === null) {
      options.push(allOption);
    } else if (typeof initialValue === 'boolean') {
      options.push(initialValue ? takenOption : inStockOption);
    } else if (Array.isArray(initialValue)) {
      customerOptions.forEach((customerOption) => {
        if (initialValue.includes(customerOption.value)) {
          options.push(customerOption);
        }
      });
    }

    return options;
  }, [initialValue, allOption, takenOption, inStockOption, customerOptions]);
}
