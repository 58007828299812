import { useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import { useAssetsTakenColumnFilterGroupedOptions } from './useAssetsTakenColumnFilterOptions';
import { AssetsTakenColumnConfig } from './AssetsTakenColumnConfig';
import { AssetsTakenColumnFilterOption } from './AssetsTakenColumnFilterOption';
import { useAssetsTakenColumnFilterInitialOptions } from './useAssetsTakenColumnFilterInitialOptions';
import { useAssetsTakenColumnFilterChangeHandler } from './useAssetsTakenColumnFilterChangeHandler';
import { useAssetsTakenColumnFilterSetter } from './useAssetsTakenColumnFilterSetter';
import { useAssetsTakenColumnFilterValue } from './useAssetsTakenColumnFilterValue';
import { AssetTakenColumnFilterSelect } from './AssetTakenColumnFilterSelect';

export const AssetTakenColumnFilter: AssetsTakenColumnConfig['component'] = ({ initialValue, filterFn }) => {
  const groupedOptions = useAssetsTakenColumnFilterGroupedOptions();
  const initialOptions = useAssetsTakenColumnFilterInitialOptions(initialValue, groupedOptions);
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<AssetsTakenColumnFilterOption>>(initialOptions);
  const changeHandler = useAssetsTakenColumnFilterChangeHandler({ groupedOptions, setSelectedOptions });
  const filterValue = useAssetsTakenColumnFilterValue(selectedOptions);

  useEffect(() => {
    setSelectedOptions(initialOptions);
  }, [initialOptions]);

  useAssetsTakenColumnFilterSetter({
    filterValue,
    filterFn,
  });

  return (
    <AssetTakenColumnFilterSelect
      groupedOptions={groupedOptions}
      selectedOptions={selectedOptions}
      changeHandler={changeHandler}
    />
  );
};
