import React, { FC, useContext, useState } from 'react';
import { ModalHeader } from '../../components/form/ModalHeader';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { DashboardDialogsAssetsSelect } from './DashboardDialogsAssetsSelect';
import { DashboardDialogsCustomerSelect } from './DashboardDialogsCustomerSelect';
import { FormModalFooter } from '../../components/form/FormModalFooter';
import { DashboardDialogAssetsRequestPayloadFactory } from './DashboardDialogAssetsRequestPayloadFactory';
import { DashboardDialogSelectOption } from './types/DashboardDialogSelectOption';
import { DashboardDialogsIssueFormDto } from './DashboardDialogsIssueFormDto';
import toast from 'react-hot-toast';
import { api } from '../../functions/api';
import { ApiPath } from '../../enums/ApiPath';
import ModalContext from 'react-bootstrap/ModalContext';
import { useSavingToastMessages } from '../../hooks/useSavingToastMessages';

export const DashboardIssueDialog: FC = () => {
  const { t } = useTranslation();
  const [assets, setAssets] = useState<DashboardDialogSelectOption[]>([]);
  const [customerId, setCustomerId] = useState<number | null>(null);
  const { onHide } = useContext(ModalContext);
  const messages = useSavingToastMessages();

  return (
    <>
      <ModalHeader>{t('Issue assets')}</ModalHeader>

      <form
        onSubmit={async (event) => {
          event.preventDefault();

          if (customerId) {
            const data: DashboardDialogsIssueFormDto = {
              ...new DashboardDialogAssetsRequestPayloadFactory(assets).getRequestPayload(),
              customerId,
            };

            await toast.promise(
              api({
                method: 'put',
                url: ApiPath.DashboardUnassignedAssets,
                data,
              }),
              messages,
            );

            onHide();
          }
        }}
      >
        <Modal.Body>
          <DashboardDialogsAssetsSelect onChange={setAssets} />

          <div className="my-2" />

          <DashboardDialogsCustomerSelect onChange={(option) => setCustomerId(option.value.id)} />
        </Modal.Body>

        <FormModalFooter disabled={assets.length === 0 || !customerId} />
      </form>
    </>
  );
};
