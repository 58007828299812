import { Customer } from '../customers/dto/Customer';

class AssetsInitialFilters {
  subcategory: string | null = null;
  customers: Customer[] | null = null;

  extractCustomers(): Customer[] | null {
    if (this.customers) {
      const customers = this.customers;
      this.customers = null;
      return customers;
    }

    return null;
  }

  extractSubcategory(): string | null {
    if (this.subcategory) {
      const subcategory = this.subcategory;
      this.subcategory = null;
      return subcategory;
    }

    return null;
  }
}

export const assetsInitialFilters = new AssetsInitialFilters();
