import { StylesConfig } from 'react-select/dist/declarations/src/styles';

export const selectCommonStyles: StylesConfig<any, any, any> = {
  input: (base) => ({
    ...base,
    margin: 0,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 4,
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#1225b1',
    color: 'white',
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: 'white',
  }),
  multiValueRemove: (base) => ({
    ...base,
    backgroundColor: '#1225b1',
    ':hover': {
      backgroundColor: '#061586',
      color: 'white',
    },
  }),
};
