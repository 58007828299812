import { action, makeObservable, observable } from 'mobx';
import { api } from '../../functions/api';
import { ApiPath } from '../../enums/ApiPath';
import { DashboardLocationsChartResponse } from './DashboardLocationsChartResponse';
import { PromiseFn } from 'react-async';

export class DashboardLocationsChartState {
  subcategories: string[] = [];

  constructor() {
    makeObservable(this, {
      subcategories: observable.ref,
      setSubcategories: action,
    });
  }

  promiseFn: PromiseFn<DashboardLocationsChartResponse> = () =>
    api<DashboardLocationsChartResponse>({
      url: ApiPath.DashboardLocations,
      method: 'post',
      data: {
        subcategories: this.subcategories,
      },
    });

  setSubcategories(value: string[]) {
    this.subcategories = value;
  }
}
