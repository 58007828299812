import { useState } from 'react';
import { AssetTakenColumnFilter } from './AssetTakenColumnFilter';
import { AssetsTakenColumnConfig } from './AssetsTakenColumnConfig';
import { useAssetsTakenColumnFilterInitialValue } from './useAssetsTakenColumnFilterInitialValue';
import { useAssetsTakenColumnFilterFn } from './useAssetsTakenColumnFilterFn';

export function useAssetsTakenColumnFilterConfig(): AssetsTakenColumnConfig {
  const filterFn = useAssetsTakenColumnFilterFn();
  const initialValue = useAssetsTakenColumnFilterInitialValue();

  return useState<AssetsTakenColumnConfig>(() => {
    return {
      component: AssetTakenColumnFilter,
      initialValue,
      filterFn,
    };
  })[0];
}
