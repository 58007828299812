import React, { DispatchWithoutAction, FC, useState } from 'react';
import { AdminToolbarButton } from '../components/toolbar/AdminToolbarButton';
import { Modal } from 'react-bootstrap';
import { useTableSelectContext } from '../components/table/TableSelectContext';
import { useTranslation } from 'react-i18next';
import { AssetsMultipleAssignFrom } from './AssetsMultipleAssignFrom';

interface Props {
  update: DispatchWithoutAction;
}

export const AssetsMultipleAssign: FC<Props> = ({ update }) => {
  const { t } = useTranslation();
  const { selectedItems } = useTableSelectContext();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <AdminToolbarButton onClick={() => setOpened(true)}>
        {t('Assign assets')} ({selectedItems.size})
      </AdminToolbarButton>

      <Modal show={opened} onHide={() => setOpened(false)}>
        <AssetsMultipleAssignFrom
          onSuccess={() => {
            update();
            setOpened(false);
          }}
        />
      </Modal>
    </>
  );
};
