import { AssetsFilterDto } from '../interfaces/AssetsFilter';
import { Asset } from '../dto/Asset';
import { ApiPath } from '../../enums/ApiPath';
import { useMemo } from 'react';
import { AsyncState, useAsync } from 'react-async';
import { useApiPromiseFn } from '../../hooks/useApiPromiseFn';

export function useAssets({ packId }: AssetsFilterDto = {}): AsyncState<Asset[]> {
  const params = useMemo(() => {
    if (packId) {
      return new URLSearchParams({
        packId: packId.toString(),
      });
    }
  }, [packId]);

  return useAsync({
    promiseFn: useApiPromiseFn(ApiPath.Assets, params),
  });
}
