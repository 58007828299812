import React, { useMemo } from 'react';
import { AdminTableColumn } from '../../components/table/interfaces/AdminTableColumn';
import { Asset } from '../dto/Asset';
import { useTranslation } from 'react-i18next';
import { AssetsTakenColumn } from '../AssetsTakenColumn';
import { useAssetsTakenColumnFilterConfig } from './useAssetsTakenColumnFilterConfig';

export function useAssetsTakenColumn(): AdminTableColumn<Asset> {
  const { t } = useTranslation();
  const filterConfig = useAssetsTakenColumnFilterConfig();

  return useMemo(
    () => ({
      title: t('Location'),
      predicate: (item) => <AssetsTakenColumn item={item} />,
      sortingPredicate: (item) => item.takenBy,
      filterConfig,
      style: {
        width: 140,
      },
    }),
    [t, filterConfig],
  );
}
