import { useMemo } from 'react';
import { AssetsTakenFilterValue } from './AssetsTakenColumnConfig';
import { AssetsTakenColumnFilterOption } from './AssetsTakenColumnFilterOption';
import { MultiValue } from 'react-select';

export function useAssetsTakenColumnFilterValue(
  selectedOptions: MultiValue<AssetsTakenColumnFilterOption>,
): AssetsTakenFilterValue {
  return useMemo(() => {
    if (selectedOptions.length) {
      if (selectedOptions.length === 1) {
        const [selectedOption] = selectedOptions;

        if (selectedOption.value === null) {
          return null;
        } else if (typeof selectedOption.value === 'boolean') {
          return selectedOption.value;
        }
      }

      return selectedOptions.reduce<string[]>((result, selectedOption) => {
        if (typeof selectedOption.value === 'string') {
          result.push(selectedOption.value);
        }

        return result;
      }, []);
    }

    return null;
  }, [selectedOptions]);
}
