import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import { AdminTableFilterProps } from '../interfaces/AdminTableFilterProps';
import { useDebounce } from 'use-debounce';
import { Form } from 'react-bootstrap';
import { AdminTableContext } from '../contexts/AdminTableContext';

export const AdminTableInputFilter: FC<AdminTableFilterProps<string>> = ({ filterFn, initialValue }) => {
  const { setFilterFunctions } = useContext(AdminTableContext);
  const [value, setValue] = useState<string>(() => initialValue || '');
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value.toLowerCase()),
    [setValue],
  );
  const [debouncedValue] = useDebounce<string>(value, 250);

  useEffect(() => {
    if (debouncedValue) {
      const filterFunction = (item: any) => filterFn(item, debouncedValue);

      setFilterFunctions((items) => items.concat(filterFunction));

      return () => {
        setFilterFunctions((items) => items.filter((item) => item !== filterFunction));
      };
    }
  }, [debouncedValue, filterFn, setFilterFunctions]);

  return <Form.Control type="search" size="sm" className="AdminTableInputFilter" value={value} onChange={onChange} />;
};
