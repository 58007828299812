import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import { AdminTableFilterProps } from '../interfaces/AdminTableFilterProps';
import { Form } from 'react-bootstrap';
import { AdminTableContext } from '../contexts/AdminTableContext';
import { useTranslation } from 'react-i18next';

export const AdminTableBooleanFilter: FC<AdminTableFilterProps<boolean>> = ({ filterFn, initialValue }) => {
  const { setFilterFunctions } = useContext(AdminTableContext);
  const { t } = useTranslation();
  const [value, setValue] = useState<boolean | null>(typeof initialValue === 'boolean' ? initialValue : null);
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setValue(value === '' ? null : value === '1');
    },
    [setValue],
  );

  useEffect(() => {
    if (value !== null) {
      const filterFunction = (item: any) => filterFn(item, value);

      setFilterFunctions((items) => items.concat(filterFunction));

      return () => {
        setFilterFunctions((items) => items.filter((item) => item !== filterFunction));
      };
    }
  }, [value, filterFn, setFilterFunctions]);
  const valueString: string = value ? '1' : value === null ? '' : '0';

  return (
    <Form.Control as="select" size="sm" className="AdminTableBooleanFilter" value={valueString} onChange={onChange}>
      <option value="" />
      <option value="1">{t('Yes')}</option>
      <option value="0">{t('No')}</option>
    </Form.Control>
  );
};
