import React, { FC, useEffect, useState } from 'react';
import { AdminTable } from '../components/table/AdminTable';
import { AdminToolbar } from '../components/toolbar/AdminToolbar';
import { AdminToolbarTitle } from '../components/toolbar/AdminToolbarTitle';
import { Asset } from './dto/Asset';
import { AdminToolbarActions } from '../components/toolbar/AdminToolbarActions';
import { AdminToolbarButton } from '../components/toolbar/AdminToolbarButton';
import { AssetsCreate } from './AssetsCreate';
import { Modal } from 'react-bootstrap';
import { AssetsEdit } from './AssetsEdit';
import { useAssetColumns } from './hooks/useAssetColumns';
import { useTranslation } from 'react-i18next';
import { TableSelectContext, useTableSelectContextValue } from '../components/table/TableSelectContext';
import { AssetsMultipleAssign } from './AssetsMultipleAssign';
import { AssetsFilter } from './AssetsFilter';
import { useAssets } from './hooks/useAssets';
import { useAssetsPackSearchParam } from './hooks/useAssetsPackSearchParam';
import { useAssetsSelectCondition } from './hooks/useAssetsSelectCondition';
import { useAssetsActions } from './hooks/useAssetsActions';

export const Assets: FC = () => {
  const packId = useAssetsPackSearchParam();
  const { data: items = [], isLoading, reload } = useAssets({ packId });
  const { t } = useTranslation();
  const [editableAsset, setEditableAsset] = useState<Asset | null>(null);
  const actions = useAssetsActions({
    setEditableAsset,
    reload,
  });
  const columns = useAssetColumns();
  const [adding, setAdding] = useState(false);
  const [selectConditionFn, setSelectedItems] = useAssetsSelectCondition();
  const selectContextValue = useTableSelectContextValue<Asset>(items, selectConditionFn);

  useEffect(() => {
    setSelectedItems(selectContextValue.selectedItems);
  }, [selectContextValue.selectedItems, setSelectedItems]);

  return (
    <TableSelectContext.Provider value={selectContextValue}>
      <AdminToolbar>
        <AdminToolbarTitle>{t('Assets')}</AdminToolbarTitle>

        <AssetsFilter />

        <TableSelectContext.Consumer>
          {({ selectedItems }) => (selectedItems.size ? <AssetsMultipleAssign update={reload} /> : null)}
        </TableSelectContext.Consumer>

        <AdminToolbarActions>
          <AdminToolbarButton onClick={() => setAdding(true)}>{t('Add asset')}</AdminToolbarButton>
        </AdminToolbarActions>
      </AdminToolbar>

      <AdminTable items={items} loading={isLoading} columns={columns} actions={actions} />

      <Modal show={adding} onHide={() => setAdding(false)}>
        <AssetsCreate update={reload} />
      </Modal>

      <Modal show={!!editableAsset} onHide={() => setEditableAsset(null)}>
        {editableAsset ? <AssetsEdit update={reload} asset={editableAsset} setAsset={setEditableAsset} /> : null}
      </Modal>
    </TableSelectContext.Provider>
  );
};
