import React, { DispatchWithoutAction, FC, useContext } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useAssetsCreateForm } from './hooks/useAssetsCreateForm';
import { FormProvider } from 'react-hook-form';
import ModalContext from 'react-bootstrap/ModalContext';
import { AssetDto } from './dto/AssetDto';
import { api } from '../functions/api';
import { ApiPath } from '../enums/ApiPath';
import { useSaveItem } from '../hooks/useSaveItem';
import { createAssetFormData } from './functions/createAssetFormData';
import { useTranslation } from 'react-i18next';
import { FormModalFooter } from '../components/form/FormModalFooter';
import { ModalHeader } from '../components/form/ModalHeader';
import { AssetsFields } from './AssetsFields';

interface Props {
  update: DispatchWithoutAction;
}

export const AssetsCreate: FC<Props> = ({ update }) => {
  const { t } = useTranslation();
  const form = useAssetsCreateForm();
  const { onHide } = useContext(ModalContext);
  const save = useSaveItem<AssetDto>((data) =>
    api({
      url: ApiPath.Assets,
      method: 'post',
      data: createAssetFormData(data),
    }),
  );

  const onSubmit = (data: AssetDto) => {
    save(data).then(() => {
      update();
      onHide();
    });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)} encType="multipart/form-data">
        <ModalHeader>{t('Add new asset')}</ModalHeader>

        <Modal.Body>
          <AssetsFields />
        </Modal.Body>

        <FormModalFooter />
      </Form>
    </FormProvider>
  );
};
