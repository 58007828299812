import React, { ReactNode } from 'react';
import './DashboardTableAsset.scss';
import { observer } from 'mobx-react-lite';
import { DashboardTableBaseAsset } from './types/DashboardTableBaseAsset';
import { DashboardTableFirstColumn } from './DashboardTableFirstColumn';
import { DashboardTableSummary } from './DashboardTableSummary';
import { DashboardTableCustomer } from './DashboardTableCustomer';

export const DashboardTableAsset = observer(function <T extends DashboardTableBaseAsset>({
  asset,
  actions,
}: {
  asset: T;
  actions: ReactNode;
}) {
  return (
    <div className="DashboardTableAsset">
      <DashboardTableFirstColumn>{asset.guid}</DashboardTableFirstColumn>
      <DashboardTableSummary>{asset.subcategory}</DashboardTableSummary>

      {asset.takenBy && <DashboardTableCustomer>{asset.takenBy}</DashboardTableCustomer>}

      {actions}
    </div>
  );
});
