import { action, autorun, computed, makeObservable, observable, ObservableMap, reaction } from 'mobx';
import { DashboardTableBaseAsset } from './DashboardTableBaseAsset';
import { DashboardBaseAsset } from '../../types/DashboardBaseAsset';
import { DispatchWithoutAction } from 'react';
import { DashboardTableCancelable } from './DashboardTableCancelable';
import { toastError } from '../../../functions/toastError';

export abstract class DashboardTableBaseBatch<
  T extends DashboardTableBaseAsset,
  A extends DashboardBaseAsset = any,
> extends DashboardTableCancelable {
  readonly date: Date;
  readonly assetsById: ObservableMap<number, T>;

  protected constructor(public readonly dateString: string, assets: A[], public remove: DispatchWithoutAction) {
    super();

    this.assetsById = observable.map<number, T>(assets.map((asset) => [asset.id, this.createAsset(asset)]));
    this.date = new Date(dateString);

    makeObservable(this, {
      assetsById: observable,
      assets: computed,
      categories: computed,
      customerNames: computed,
      canBeProcessedAssets: computed,
      batchRequestInProgress: computed,
      processing: computed,
      updateAssets: action,
      removeAsset: action,
      remove: action,
      startBatchProcessing: action,
    });

    reaction(
      () => this.cancelCountdown,
      (value) => {
        if (value) {
          this.canBeProcessedAssets.forEach((asset) => {
            asset.setBatchCancelCountdown(value);
          });
        } else {
          this.batchCancelCountdownAssets.forEach((asset) => {
            asset.setBatchCancelCountdown(null);
          });
        }
      },
    );

    autorun(() => {
      if (this.assets.every((asset) => asset.batchCancelCountdown === null) && this.cancelCountdown !== null) {
        this.stopCancelCountdown();
      }
    });
  }

  get batchCancelCountdownAssets() {
    return this.assets.filter((asset) => asset.batchCancelCountdown);
  }

  get canBeProcessedAssets(): T[] {
    return this.assets.filter((asset) => asset.canBeProcessed);
  }

  get batchRequestInProgress(): boolean {
    return this.assets.some((asset) => asset.batchRequestInProgress);
  }

  get processing(): boolean {
    return this.assets.some((asset) => asset.processing);
  }

  get assets(): T[] {
    return Array.from(this.assetsById.values()).sort((a, b) => a.guid.localeCompare(b.guid));
  }

  get categories(): string {
    const set = new Set<string>();

    this.assets.forEach((asset) => {
      set.add(asset.category);
    });

    return Array.from(set.values()).sort().join(', ');
  }

  get customerNames(): string {
    const set = new Set<string>();

    this.assets.forEach((asset) => {
      if (asset.takenBy) {
        set.add(asset.takenBy);
      }
    });

    return Array.from(set.values()).sort().join(', ');
  }

  abstract createAsset(asset: A): T;

  updateAssets(assets: A[]): void {
    const assetIds = new Set<number>();

    assets.forEach((asset) => {
      assetIds.add(asset.id);

      if (!this.assetsById.has(asset.id)) {
        this.assetsById.set(asset.id, this.createAsset(asset));
      }
    });

    this.removeMissingAssets(assetIds);
  }

  removeAsset(id: number): void {
    this.assetsById.delete(id);

    if (this.assetsById.size === 0) {
      this.remove();
    }
  }

  async startBatchProcessing(action: (assets: T[]) => Promise<void>) {
    await this.startCancelCountdown().promise;

    const assets = this.batchCancelCountdownAssets;

    assets.forEach((asset) => asset.setBatchRequestInProgress(true));

    try {
      await action(assets);

      assets.forEach((asset) => asset.remove());
    } catch (e) {
      toastError();
      throw e;
    } finally {
      assets.forEach((asset) => asset.setBatchRequestInProgress(false));
    }
  }

  async delete() {
    if (this.cancelCountdown) {
      this.stopCancelCountdown();
    }

    await Promise.all(this.assets.map((asset) => asset.delete()));
  }

  /**
   * The new set of assets may not have a previously existing ones. There may be situations where
   * some assets have been seen at the gate multiple times, so their date will be changed.
   */
  private removeMissingAssets(assetIds: Set<number>) {
    this.assetsById.forEach((_, id) => {
      if (!assetIds.has(id)) {
        this.assetsById.delete(id);
      }
    });
  }
}
