import { FC, useContext, useMemo } from 'react';
import { DashboardChart } from './DashboardChart';
import { useTranslation } from 'react-i18next';
import { Pie } from 'react-chartjs-2';
import './DashboardLocationsChart.scss';
import { DashboardChartSelect } from './DashboardChartSelect';
import { colors } from '../../colors';
import { Context } from 'chartjs-plugin-datalabels/types/context';
import { sortBy, sumBy } from 'lodash';
import { useDashboardLocationsChartOptions } from './useDashboardLocationsChartOptions';
import { observer } from 'mobx-react-lite';
import { DashboardContext } from '../DashboardContext';
import { DashboardLocationsChartResponse } from './DashboardLocationsChartResponse';

interface DataItem {
  title: string;
  count: number;
  percent: number;
}

const initialState: DashboardLocationsChartResponse = {
  sites: [],
};

export const DashboardLocationsChart: FC = observer(() => {
  const { t } = useTranslation();
  const options = useDashboardLocationsChartOptions();
  const {
    locationsChartState,
    locationsAsyncState: { data: { sites } = initialState },
  } = useContext(DashboardContext);
  const data: DataItem[] = useMemo(() => {
    const total = sumBy(sites, ({ count }) => count);

    return sortBy(sites, (site) => site.count).map(({ count, title }) => ({
      title,
      count,
      percent: Math.round((100 * count) / total),
    }));
  }, [sites]);

  return (
    <DashboardChart
      className="DashboardLocationsChart"
      title={t('Assets locations').toString()}
      filter={
        <DashboardChartSelect
          options={options}
          onChange={(selectedOptions) => {
            locationsChartState.setSubcategories(selectedOptions.map((selectedOption) => selectedOption.value));
          }}
          placeholder={t('Select asset')}
        />
      }
    >
      <Pie
        className="mx-auto"
        options={{
          plugins: {
            datalabels: {
              display: (context: Context) => data[context.dataIndex].percent > 5,
              formatter: (_, context: Context) => `${data[context.dataIndex].percent}%`,
              color: 'white',
            },
          },
        }}
        data={{
          labels: data.map(({ title }) => title),
          datasets: [
            {
              label: t('Assets').toString(),
              data: data.map(({ count }) => count),
              backgroundColor: colors,
            },
          ],
        }}
      />
    </DashboardChart>
  );
});
