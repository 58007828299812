import { ApiPath } from '../enums/ApiPath';
import { AsyncState, useAsync } from 'react-async';
import { Customer } from '../customers/dto/Customer';
import { useApiPromiseFn } from './useApiPromiseFn';

export function useCustomers(): AsyncState<Customer[]> {
  return useAsync({
    promiseFn: useApiPromiseFn(ApiPath.Customers),
  });
}
