import { useForm, UseFormReturn } from 'react-hook-form';
import { AssetsMultipleAssignFromData } from '../dto/AssetsMultipleAssignFromData';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ISchema } from 'yup';
import { useTableSelectContext } from '../../components/table/TableSelectContext';
import { Asset } from '../dto/Asset';

export function useAssetsMultipleAssignmentForm(reassign: boolean): UseFormReturn<AssetsMultipleAssignFromData> {
  const { t } = useTranslation();
  const { selectedItems } = useTableSelectContext<Asset>();
  const resolver = useMemo(
    () =>
      yupResolver(
        yup.object().shape<{ [key in keyof AssetsMultipleAssignFromData]: ISchema<any> }>({
          customer: yup
            .object()
            .label(t('Customer'))
            .required(t('Please select a customer').toString())
            .typeError(t('Please select a customer').toString()),
          assetIds: yup.array().label(t('Assets')).of(yup.number()).required(),
          reassign: yup.boolean(),
        }),
      ),
    [t],
  );
  const [defaultValues] = useState(() => {
    const assetIds: number[] = [];

    selectedItems.forEach((selectedItem) => {
      assetIds.push(selectedItem.id);
    });

    return { assetIds, reassign } as AssetsMultipleAssignFromData;
  });

  return useForm({
    resolver,
    defaultValues,
  });
}
