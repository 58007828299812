import { AssetsTakenColumnConfig } from './AssetsTakenColumnConfig';

export function useAssetsTakenColumnFilterFn(): AssetsTakenColumnConfig['filterFn'] {
  return (item, value) => {
    if (value === null) {
      return true;
    } else if (value === false) {
      return !item.takenBy;
    } else if (value === true) {
      return !!item.takenBy;
    } else {
      return item.takenBy ? value.includes(item.takenBy) : false;
    }
  };
}
