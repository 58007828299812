import React, { DispatchWithoutAction, FC } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { ModalHeader } from '../components/form/ModalHeader';
import { FormModalFooter } from '../components/form/FormModalFooter';
import { useAssetsMultipleAssignmentForm } from './hooks/useAssetsMultipleAssignmentForm';
import { FormField } from '../components/form/FormField';
import { AssetsMultipleAssignFromCustomer } from './AssetsMultipleAssignFromCustomer';
import { useAssetsMultipleAssignFromSubmitHandler } from './useAssetsMultipleAssignFromSubmitHandler';
import { useAssetsMultipleReassign } from './hooks/useAssetsMultipleReassign';

interface Props {
  onSuccess: DispatchWithoutAction;
}

export const AssetsMultipleAssignFrom: FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const reassign = useAssetsMultipleReassign();
  const form = useAssetsMultipleAssignmentForm(reassign);
  const submitHandler = useAssetsMultipleAssignFromSubmitHandler(onSuccess);

  return (
    <>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(submitHandler)}>
          <ModalHeader>{t('Multiple assignment')}</ModalHeader>

          <Modal.Body>
            <FormField label={t('Customer').toString()} name="customer">
              <AssetsMultipleAssignFromCustomer reassign={reassign} />
            </FormField>
          </Modal.Body>

          <FormModalFooter disabled={!form.formState.isValid} />
        </Form>
      </FormProvider>
    </>
  );
};
