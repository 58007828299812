import { ActionMeta, MultiValue } from 'react-select';
import { AssetsTakenColumnFilterOption } from './AssetsTakenColumnFilterOption';
import { Dispatch, SetStateAction } from 'react';
import { AssetsTakenColumnFilterGroupedOptions } from './useAssetsTakenColumnFilterOptions';

export type AssetsTakenColumnFilterChangeHandler = (
  options: MultiValue<AssetsTakenColumnFilterOption>,
  meta: ActionMeta<AssetsTakenColumnFilterOption>,
) => void;

export function useAssetsTakenColumnFilterChangeHandler({
  groupedOptions,
  setSelectedOptions,
}: {
  groupedOptions: AssetsTakenColumnFilterGroupedOptions;
  setSelectedOptions: Dispatch<SetStateAction<MultiValue<AssetsTakenColumnFilterOption>>>;
}): AssetsTakenColumnFilterChangeHandler {
  return (options, meta) => {
    if (meta.action === 'select-option') {
      const { option } = meta;

      if (option) {
        if (typeof option.value === 'boolean') {
          setSelectedOptions([option.value ? groupedOptions.takenOption : groupedOptions.inStockOption]);
        } else if (option.value === null) {
          setSelectedOptions([groupedOptions.allOption]);
        } else {
          setSelectedOptions((selectedOptions) =>
            selectedOptions.filter((selectedOption) => typeof selectedOption.value === 'string').concat(option),
          );
        }
      }
    } else if (options.length) {
      setSelectedOptions(options);
    } else {
      setSelectedOptions([groupedOptions.allOption]);
    }
  };
}
