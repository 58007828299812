import React, { FC } from 'react';
import { OptionalDateRange } from '../interfaces/OptionalDateRange';
import './AdminTableDateFilterValue.scss';
import { DateFormatted } from '../../DateFormatted';
import { SHORT_DATE_FORMAT } from '../../../constants';
import { useTranslation } from 'react-i18next';

interface Props {
  range: OptionalDateRange;
}

export const AdminTableDateFilterValue: FC<Props> = ({ range }) => {
  const { t } = useTranslation();

  return (
    <div className="AdminTableDateFilterValue">
      {range[0] && (
        <>
          {range[1] ? '' : t('From') + ' '}

          <DateFormatted className="date" format={SHORT_DATE_FORMAT} date={range[0]} />
        </>
      )}

      {range[1] && (
        <>
          {range[0] ? '–' : t('To') + ' '}

          <DateFormatted className="date" format={SHORT_DATE_FORMAT} date={range[1]} />
        </>
      )}
    </div>
  );
};
