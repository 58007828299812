import { FC } from 'react';
import { Form } from 'react-bootstrap';
import { FormField } from './form/FormField';
import { useTranslation } from 'react-i18next';

export const NotesFormField: FC = () => {
  const { t } = useTranslation();

  return (
    <FormField
      label={t('Notes').toString()}
      name="notes"
      hint={t('Displayed only here').toString()}
      render={({ controlAttrs }) => <Form.Control as="textarea" {...controlAttrs} rows={3} />}
    />
  );
};
