import { Dispatch } from 'react';
import Select from 'react-select';
import { FormSelectOption } from '../../components/form/FormSelect';
import './DashboardChartSelect.scss';
import { selectCommonStyles } from '../../components/selectCommonStyles';
import { selectCommonComponents } from '../../components/selectCommonComponents';

export function DashboardChartSelect<T extends FormSelectOption<any>>({
  options,
  onChange,
  placeholder,
}: {
  options: T[];
  onChange: Dispatch<T[]>;
  placeholder: string;
}) {
  return (
    <Select
      options={options}
      onChange={(options) => {
        onChange(options.slice());
      }}
      isMulti
      isRtl={process.env.REACT_APP_LANG === 'he'}
      placeholder={placeholder}
      components={selectCommonComponents}
      styles={{
        ...selectCommonStyles,
        container: (base) => ({
          ...base,
          minWidth: 160,
        }),
      }}
    />
  );
}
