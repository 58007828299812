import React, { Dispatch } from 'react';
import Select from 'react-select';
import { FormSelectOption } from '../../components/form/FormSelect';
import { useTranslation } from 'react-i18next';
import { Customer } from '../../customers/dto/Customer';
import { selectCommonStyles } from '../../components/selectCommonStyles';
import { selectCommonComponents } from '../../components/selectCommonComponents';

import { useDashboardCustomerSelectOptions } from '../hooks/useDashboardCustomerSelectOptions';

export function DashboardDialogsCustomerSelect({ onChange }: { onChange: Dispatch<FormSelectOption<Customer>> }) {
  const { t } = useTranslation();
  const options = useDashboardCustomerSelectOptions();

  return (
    <Select
      options={options}
      onChange={onChange}
      isClearable={false}
      isRtl={process.env.REACT_APP_LANG === 'he'}
      placeholder={t('Select customer')}
      components={selectCommonComponents}
      styles={selectCommonStyles}
    />
  );
}
