import { DashboardBaseAsset } from '../../types/DashboardBaseAsset';
import { DispatchWithoutAction } from 'react';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { DashboardTableCancelable, DashboardTableCancelCountdown } from './DashboardTableCancelable';
import { toastError } from '../../../functions/toastError';
import { api } from '../../../functions/api';
import { ApiPath } from '../../../enums/ApiPath';

export class DashboardTableBaseAsset extends DashboardTableCancelable {
  readonly id: number;
  readonly guid: string;
  readonly category: string;
  readonly subcategory: string;
  takenBy: string | null;

  requestInProgress = false;
  batchRequestInProgress = false;

  batchCancelCountdown: DashboardTableCancelCountdown | null = null;

  /**
   * Just removes an asset from the UI table
   */
  remove: DispatchWithoutAction;

  constructor(asset: DashboardBaseAsset, remove: DispatchWithoutAction) {
    super();

    this.id = asset.id;
    this.guid = asset.guid;
    this.category = asset.category;
    this.subcategory = asset.subcategory;
    this.takenBy = asset.takenBy || null;
    this.remove = remove;

    makeObservable(this, {
      takenBy: observable,
      requestInProgress: observable,
      batchRequestInProgress: observable,
      batchCancelCountdown: observable.ref,
      canBeProcessed: computed,
      processing: computed,
      setRequestInProgress: action,
      setBatchCancelCountdown: action,
      setBatchRequestInProgress: action,
      startProcessing: action,
      setTakenBy: action,
      remove: action,
    });

    reaction(
      () => this.batchRequestInProgress,
      (value) => {
        this.requestInProgress = value;
      },
    );

    reaction(
      () => this.batchCancelCountdown,
      (value) => {
        this.cancelCountdown = value;
      },
    );
  }

  get canBeProcessed(): boolean {
    return !this.processing;
  }

  get processing(): boolean {
    return this.requestInProgress || !!this.cancelCountdown?.inProgress;
  }

  async startProcessing(action: () => Promise<void>): Promise<void> {
    await this.startCancelCountdown().promise;

    this.setRequestInProgress(true);

    try {
      await action();
      this.remove();
    } catch (e) {
      toastError();
      throw e;
    } finally {
      this.setRequestInProgress(false);
    }
  }

  setRequestInProgress(value: boolean) {
    this.requestInProgress = value;
  }

  setBatchRequestInProgress(value: boolean) {
    this.batchRequestInProgress = value;
  }

  setBatchCancelCountdown(value: DashboardTableCancelCountdown | null) {
    this.batchCancelCountdown = value;
  }

  setTakenBy(value: string | null) {
    this.takenBy = value;
  }

  /**
   * Stops countdown, deletes asset's pending transaction from DB and removes it from the UI table
   */
  async delete(): Promise<void> {
    if (this.cancelCountdown) {
      this.stopCancelCountdown();
    }

    await api({
      url: ApiPath.AssetPendingTransaction.replace(':guid', encodeURIComponent(this.guid)),
      method: 'delete',
    });

    this.remove();
  }
}
