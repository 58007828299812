import { action, makeObservable, observable } from 'mobx';
import { DashboardIssuedChartResponse } from './DashboardIssuedChartResponse';
import { api } from '../../functions/api';
import { ApiPath } from '../../enums/ApiPath';
import { PromiseFn } from 'react-async';
import { Customer } from '../../customers/dto/Customer';

export class DashboardIssuedChartState {
  customers: Customer[] = [];

  constructor() {
    makeObservable(this, {
      customers: observable.ref,
      setCustomers: action,
    });
  }

  promiseFn: PromiseFn<DashboardIssuedChartResponse> = () =>
    api<DashboardIssuedChartResponse>({
      url: ApiPath.DashboardIssuedChart,
      method: 'post',
      data: {
        customers: this.customers.map((customer) => customer.id),
      },
    });

  setCustomers(value: Customer[]) {
    this.customers = value;
  }
}
