import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import { CustomerDto } from '../dto/CustomerDto';
import { Customer } from '../dto/Customer';
import { useTranslation } from 'react-i18next';

export function useCustomersEditForm(customer: Customer): UseFormReturn<CustomerDto> {
  const { t } = useTranslation();

  return useForm({
    resolver: useMemo(
      () =>
        yupResolver(
          yup.object().shape({
            name: yup.string().label(t('Name')).required(),
            notes: yup.string().label(t('Notes')),
            email: yup.string().label(t('Email')),
          }),
        ),
      [t],
    ),
    defaultValues: {
      name: customer.name,
      notes: customer.notes,
      email: customer.email,
    },
  });
}
