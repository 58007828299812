import { DashboardTableBaseAsset } from '../types/DashboardTableBaseAsset';
import { action, makeObservable } from 'mobx';
import { DispatchWithoutAction } from 'react';
import { DashboardReturnedAsset } from '../../types/DashboardReturnedAsset';
import { assignAssets } from '../assignAssets';

export class DashboardTableReturnedAsset extends DashboardTableBaseAsset {
  constructor(public baseAsset: DashboardReturnedAsset, remove: DispatchWithoutAction) {
    super(baseAsset, remove);

    makeObservable(this, {
      approve: action,
    });
  }

  async approve() {
    await this.startProcessing(async () => {
      await assignAssets([this.id]);
    }).finally(() => {
      this.setCancelCountdown(null);
    });
  }
}
