import React, { FC, PropsWithChildren } from 'react';
import { Modal } from 'react-bootstrap';

export const ModalHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Modal.Header closeButton>
      <Modal.Title>{children}</Modal.Title>
    </Modal.Header>
  );
};
