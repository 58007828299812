export class Deferred<T = void, E = void> {
  resolve: (value: T) => void;
  reject: (value: E) => void;
  promise: Promise<T>;

  constructor() {
    let resolve!: (value: T) => void;
    let reject!: (value: E) => void;

    this.promise = new Promise<T>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    this.resolve = resolve;
    this.reject = reject;
  }
}
