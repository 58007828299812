import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { DashboardLocationsChart } from './DashboardLocationsChart';
import { DashboardIssuedChart } from './DashboardIssuedChart';
import './DashboardCharts.scss';

export const DashboardCharts: FC = observer(() => {
  return (
    <div className="DashboardCharts">
      <DashboardLocationsChart />
      <DashboardIssuedChart />
    </div>
  );
});
