import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { stubTrue } from 'lodash';
import { TableSelectConditionFn } from '../../components/table/TableSelectContext';
import { Asset } from '../dto/Asset';

export function useAssetsSelectCondition(): [TableSelectConditionFn<Asset>, Dispatch<SetStateAction<Set<Asset>>>] {
  const [selectedItems, setSelectedItems] = useState<Set<Asset>>(() => new Set());

  const selectConditionFn: TableSelectConditionFn<Asset> = useMemo(
    () => (selectedItems.size ? createConditionFn(isSomeTaken(selectedItems)) : stubTrue),
    [selectedItems],
  );

  return [selectConditionFn, setSelectedItems];
}

function createConditionFn(someTaken: boolean): TableSelectConditionFn<Asset> {
  return someTaken ? (item: Asset) => !!item.takenBy : (item: Asset) => !item.takenBy;
}

function isSomeTaken(selectedItems: Set<Asset>): boolean {
  const [selectedItem] = selectedItems;

  return selectedItem ? !!selectedItem.takenBy : false;
}
