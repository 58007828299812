import React, { FC } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormField } from '../components/form/FormField';
import { useTranslation } from 'react-i18next';
import { NotesFormField } from '../components/NotesFormField';

export const CustomerFormBody: FC = () => {
  const { t } = useTranslation();

  return (
    <Modal.Body>
      <FormField
        label={t('Name').toString()}
        name="name"
        render={({ invalid, controlAttrs }) => <Form.Control {...controlAttrs} isInvalid={invalid} autoFocus />}
      />

      <FormField
        label={t('Email').toString()}
        name="email"
        render={({ invalid, controlAttrs }) => <Form.Control {...controlAttrs} isInvalid={invalid} autoFocus />}
      />

      <NotesFormField />

    </Modal.Body>
  );
};
