import { FC, PropsWithChildren } from 'react';
import './DashboardTableActions.scss';

export const DashboardTableActions: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className="DashboardTableActions"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {children}
    </div>
  );
};
