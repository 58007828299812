import { useContext, useMemo } from 'react';
import { DashboardContext } from '../DashboardContext';
import { FormSelectOption } from '../../components/form/FormSelect';

export function useDashboardLocationsChartOptions(): FormSelectOption[] {
  const { data } = useContext(DashboardContext).assets;

  return useMemo(() => {
    if (data) {
      const subcategories = new Set<string>();

      data.forEach((asset) => asset.subcategory && subcategories.add(asset.subcategory));

      return Array.from(subcategories.values()).map((subcategory) => ({
        value: subcategory,
        label: subcategory,
      }));
    }

    return [];
  }, [data]);
}
