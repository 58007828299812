import { FC, useContext } from 'react';
import './DashboardTable.scss';
import { DashboardContext } from '../DashboardContext';
import { DashboardTableContentType } from '../enums/DashboardTableContentType';
import { observer } from 'mobx-react-lite';
import { DashboardTableUnassigned } from './unassigned/DashboardTableUnassigned';
import { DashboardTableReturned } from './returned/DashboardTableReturned';
import { useDashboardLeaveConfirm } from '../hooks/useDashboardLeaveConfirm';

export const DashboardTable: FC = observer(() => {
  const { tableContentType, setTableContainerElement } = useContext(DashboardContext);

  useDashboardLeaveConfirm();

  return (
    <div className="DashboardTable" ref={setTableContainerElement}>
      {tableContentType === DashboardTableContentType.Unassigned ? (
        <DashboardTableUnassigned />
      ) : (
        <DashboardTableReturned />
      )}
    </div>
  );
});
