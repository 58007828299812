import { Dispatch, useCallback } from 'react';
import { getTemporaryToken } from '../functions/getTemporaryToken';
import { VILOG_URL } from '../constants';

const url = VILOG_URL.endsWith('/') ? VILOG_URL : VILOG_URL + '/';

export function useOpenVilog(): Dispatch<string> {
  return useCallback(async (path: string) => {
    const token = await getTemporaryToken();

    window.open(
      `${url}login/assets?path=${window.encodeURIComponent(path)}&token=${window.encodeURIComponent(token)}`,
      '_blank',
    );
  }, []);
}
