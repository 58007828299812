import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeader } from '../../components/form/ModalHeader';
import { Modal } from 'react-bootstrap';
import { DashboardDialogsAssetsSelect } from './DashboardDialogsAssetsSelect';
import { FormModalFooter } from '../../components/form/FormModalFooter';
import { DashboardDialogAssetsRequestPayloadFactory } from './DashboardDialogAssetsRequestPayloadFactory';
import { DashboardDialogSelectOption } from './types/DashboardDialogSelectOption';
import { api } from '../../functions/api';
import { ApiPath } from '../../enums/ApiPath';
import toast from 'react-hot-toast';
import ModalContext from 'react-bootstrap/ModalContext';
import { useSavingToastMessages } from '../../hooks/useSavingToastMessages';
import { DashboardDialogsReturnFormDto } from './DashboardDialogsReturnFormDto';

export const DashboardReturnDialog: FC = () => {
  const { t } = useTranslation();
  const [assets, setAssets] = useState<DashboardDialogSelectOption[]>([]);
  const { onHide } = useContext(ModalContext);
  const messages = useSavingToastMessages();

  return (
    <>
      <ModalHeader>{t('Return assets')}</ModalHeader>

      <form
        onSubmit={async (event) => {
          event.preventDefault();

          const data: DashboardDialogsReturnFormDto = new DashboardDialogAssetsRequestPayloadFactory(
            assets,
          ).getRequestPayload();

          await toast.promise(
            api({
              method: 'put',
              url: ApiPath.DashboardReturnedAssets,
              data,
            }),
            messages,
          );

          onHide();
        }}
      >
        <Modal.Body>
          <DashboardDialogsAssetsSelect onChange={setAssets} />
        </Modal.Body>

        <FormModalFooter disabled={assets.length === 0} />
      </form>
    </>
  );
};
