import React, { FC } from 'react';
import './AdminTableLoader.scss';
import { Spinner } from '../Spinner';
import { useTranslation } from 'react-i18next';

export const AdminTableLoader: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="AdminTableLoader">
      <Spinner className="icon" borderWidth={1.25} />

      <span className="text">{t('Loading')}</span>
    </div>
  );
};
