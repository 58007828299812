import { DispatchWithoutAction, FC, ReactNode } from 'react';
import './DashboardReport.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export const DashboardReport: FC<{
  title: ReactNode;
  value: ReactNode;
  icon?: ReactNode;
  onClick?: DispatchWithoutAction;
  to?: string;
  active?: boolean;
}> = ({ title, value, onClick, to, active, icon }) => {
  const content = (
    <>
      <header className="header">
        <div className="title">{title}</div>

        {icon ? <div className="icon">{icon}</div> : null}
      </header>

      <div className="value">{value}</div>
    </>
  );

  return onClick ? (
    <button
      className={classNames('DashboardReport -clickable', {
        '-active': active,
      })}
      onClick={onClick}
    >
      {content}
    </button>
  ) : to ? (
    <Link className="DashboardReport -clickable" to={to}>
      {content}
    </Link>
  ) : (
    <div className="DashboardReport">{content}</div>
  );
};
