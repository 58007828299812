import { Asset } from '../../assets/dto/Asset';
import { AssetWithSubcategory, isAssetWithSubcategory } from './types/AssetWithSubcategory';
import { DashboardDialogSelectOption } from './types/DashboardDialogSelectOption';
import { DashboardDialogAssetOption } from './types/DashboardDialogAssetOption';
import { DashboardDialogSubcategoryOption } from './types/DashboardDialogSubcategoryOption';

export class DashboardDialogAssetOptionsFactory {
  private options: DashboardDialogSelectOption[] = [];
  private processedSubcategories = new Set<string>();
  private selectedSubcategoryOptions: Map<string, DashboardDialogSubcategoryOption[]>;

  constructor(private assets: Asset[], selectedOptions: DashboardDialogSelectOption[]) {
    this.selectedSubcategoryOptions = this.getSelectedSubcategoryOptions(selectedOptions);
  }

  getOptions(): DashboardDialogSelectOption[] {
    this.assets.forEach((asset) => this.createOptionsForAsset(asset));

    return this.options;
  }

  private getSelectedSubcategoryOptions(
    selectedOptions: DashboardDialogSelectOption[],
  ): Map<string, DashboardDialogSubcategoryOption[]> {
    const result = new Map<string, DashboardDialogSubcategoryOption[]>();

    selectedOptions.forEach((selectedOption) => {
      if (selectedOption instanceof DashboardDialogSubcategoryOption) {
        const array = result.get(selectedOption.subcategory) || [];

        array.push(selectedOption);
        result.set(selectedOption.subcategory, array);
      }
    });

    return result;
  }

  private createOptionsForAsset(asset: Asset) {
    if (isAssetWithSubcategory(asset) && this.shouldProcessSubcategory(asset)) {
      this.processSubcategory(asset.subcategory);
    }

    this.options.push(this.createAssetOption(asset));
  }

  private processSubcategory(subcategory: string) {
    this.createOptionsForSubcategory(subcategory);
    this.markSubcategoryProcessed(subcategory);
  }

  private markSubcategoryProcessed(subcategory: string) {
    this.processedSubcategories.add(subcategory);
  }

  private createOptionsForSubcategory(subcategory: string) {
    const selectedIdenticalOptions = this.selectedSubcategoryOptions.get(subcategory) || [];

    this.options.push(...selectedIdenticalOptions);
    this.options.push(this.createSubcategoryOption(subcategory, selectedIdenticalOptions));
  }

  private shouldProcessSubcategory(asset: AssetWithSubcategory): boolean {
    return !this.processedSubcategories.has(asset.subcategory);
  }

  private createSubcategoryOption(
    subcategory: string,
    selectedIdenticalOptions: DashboardDialogSubcategoryOption[],
  ): DashboardDialogSubcategoryOption {
    return new DashboardDialogSubcategoryOption(
      subcategory,
      this.getNextSubcategoryOptionIndex(selectedIdenticalOptions),
    );
  }

  private getNextSubcategoryOptionIndex(selectedIdenticalOptions: DashboardDialogSubcategoryOption[]): number {
    return selectedIdenticalOptions.reduce((result, item) => Math.max(result, item.index), -1) + 1;
  }

  private createAssetOption(asset: Asset): DashboardDialogAssetOption {
    return new DashboardDialogAssetOption(asset);
  }
}
