import { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import './DashboardTableLabel.scss';

export const DashboardTableLabel: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children, ...attrs }) => {
  return (
    <div className={classNames('DashboardTableLabel', className)} {...attrs}>
      {children}
    </div>
  );
};
