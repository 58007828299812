import { DispatchWithoutAction, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { DashboardTableActionsButton } from './DashboardTableActionsButton';
import { DashboardTableCancelCountdown } from '../types/DashboardTableCancelable';
import { useCountdownSeconds } from '../../../hooks/useCountdownSeconds';

export const DashboardTableTimerCancelButton: FC<{
  onClick: DispatchWithoutAction;
  countdown: DashboardTableCancelCountdown;
}> = observer(({ onClick, countdown }) => {
  const { t } = useTranslation();
  const seconds = useCountdownSeconds(countdown);

  return (
    <DashboardTableActionsButton onClick={onClick}>
      {t('Cancel ({{seconds}})', { seconds })}
    </DashboardTableActionsButton>
  );
});
